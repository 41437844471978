@import "src/resources/styles/variables.scss";

.event-details-sponsors-details-container {
  margin-top: 45px;
  .event-details-sponsors-title {
    font-size: 23.4px;
    line-height: 29px;
    font-weight: 500;
    margin-bottom: 18px;
    @media (max-width: 599px) {
      margin-bottom: 27px;
    }
  }
  .event-details-sponsors-details {
    margin-top: 63px;
    &:first-child {
      margin-top: 0px;
    }
    .event-details-sponsors-wrapper {
      gap: 26px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(0, 227px));
      @media (max-width: 599px) {
        grid-template-columns: 1fr;
      }

      .event-details-sponsors-img {
        width: 227px;
        height: 227px;
        background: $color-gray-very-light;
        border-radius: 9px;
        @media (max-width: 599px) {
          width: 100%;
          height: 351px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 9px;
        }
      }
      .event-details-sponsors-name {
        font-size: 18px;
        line-height: 23px;
        font-weight: 500;
        margin-top: 18px;
      }
    }
  }
}
