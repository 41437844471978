.paginations {
  .pagination {
    li {
      background: transparent;
      border: 0;

      min-width: 25px;
      min-height: 25px;
      &:first-child {
        a {
          min-width: auto;
          min-height: auto;
          background-color: transparent;
          line-height: 15px;
          display: initial;

          span {
            font-size: 30px;
            color: #aaa;
          }
        }
      }

      a {
        // background-color: $color-bg;
        border: none;
        border-radius: 50%;
        line-height: 15px;
        min-width: 25px;
        min-height: 25px;
        padding: 0;
      }

      &:last-child {
        a {
          min-width: auto;
          min-height: auto;
          background-color: transparent;
          line-height: 15px;
          display: initial;

          span {
            font-size: 30px;
            color: #aaa;
          }
        }
      }

      &.active {
        a {
          border-top: 0 !important;
        }
      }
    }
  }
}
.pagination-main {
  img {
    height: 15px;
    cursor: pointer;
  }
  .tablePagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 10px;
  }
  .pagination {
    display: flex;
    align-items: center;
  }
  ul {
    margin: 0;
    border: 0px !important;
    li {
      background: transparent;
      min-width: unset;
      border: 0;
      width: auto !important;
      @media (max-width: 599px) {
        margin: 0px !important;
      }
      button {
        padding: 0;
        height: 35px;
        width: 35px;
        margin: 0;
        min-width: 35px;
        // border: 1px solid #dddddd;
        border-radius: 50%;
        @media (max-width: 599px) {
          height: 30px;
          width: 30px;
          min-width: 30px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .btn-navigation-disabled {
    img {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
