@import 'src/resources/styles/variables.scss';
@import 'src/resources/styles/mixins.scss';

.deleteAttendees {
  position: relative;
  top: -4px;
  right: -10px;

  img {
    width: 30px;
  }
  @include mediaq(575px) {
    display: none;
  }
}

.tableHead {
  color: $color-blue-dark;
  font-weight: 500;
}

.invitedTable {
  .table {
    thead {
      th {
        border-bottom: 2px solid #dee2e6;
      }
    }
    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
  th {
    border: 0;
  }
  .field-width {
    @media (max-width: 639px) {
      min-width: 160px;
    }
  }
  .closeWithCircleIcon {
    width: 30px;
    height: 30px;
    @media (max-width: 630px) {
      width: 18px;
      height: 18px;
    }
  }
  .fullname-col {
    @media (max-width: 630px) {
      font-size: 14px;
    }
  }
  .email-col {
    @media (max-width: 630px) {
      font-size: 14px;
    }
  }
  .status-col {
    @media (max-width: 630px) {
      font-size: 14px;
    }
  }
  .existing-user-table {
    @media (max-width: 639px) {
      display: block;
      overflow-y: scroll;
    }
  }
  .invited-table {
    @media (max-width: 639px) {
      display: block;
      overflow-y: scroll;
    }
  }
  .invite-table-namecol {
    min-width: 160px;
  }
  .invite-table-statuscol {
    min-width: 141px;
  }

  td {
    vertical-align: middle;
  }
  .text-merigold {
    color: $color-light-red;
  }
  .text-red {
    color: #f44336;
  }
}

.invitedTable {
  .btn-group {
    .btn {
      border: 0;
      background-color: transparent;

      &:active {
        border-radius: 4px;
        background-color: transparent;
        border: 0;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .search-filter {
    &.input-group {
      input {
        border-right: 1px solid $color-gray;
        border-radius: 3px !important;
        height: 45px;
      }
    }

    button {
      border-radius: 3px !important;
    }
  }

  img {
    transition: all 0.5s ease-in-out;

    &.down {
      transform: rotate(180deg);
      transition: all 0.5s ease-in-out;
    }
  }
}

.tabWithBottomBorder {
  ul {
    border-bottom: 1px solid $color-gray;

    li {
      margin: 0;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      border: 0;
      background-color: transparent;
      color: $color-gray;

      a {
        color: #333333;

        &:hover {
          border-color: transparent !important;
        }
      }

      &.active {
        color: $color-black;
        border: 0;
        border-bottom: solid 5px $color-yellow;
        padding-bottom: 5px;

        a {
          color: $color-yellow;
          &:hover {
            color: $color-yellow;
          }
        }
      }
    }
  }
  .tab-design {
    cursor: pointer;
    &:hover {
      color: #333333;
    }
  }
}

.search-filter {
  input {
    min-height: 45px;
    // border-right: 0;
  }

  button {
    min-height: 45px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: $color-yellow;
    border-color: $color-yellow;
    color: $color-black;

    &:hover,
    &:focus {
      background-color: $color-yellow;
      border-color: $color-yellow;
      color: $color-black;
    }
  }
}

.customModal {
  .roleModal {
    table {
      th {
        background-color: $color-bg;
        border: 1px solid $color-gray;
      }

      tbody {
        tr {
          td {
            border-color: $color-gray;
            border: 1px solid $color-gray;
            &:first-child {
              background-color: $color-bg;
            }
          }
        }
      }
    }
    .role-table {
      @media (max-width: 639px) {
        display: block;
        overflow-y: scroll;
      }
    }
  }
}

.tabInfoIcon {
  position: relative;
  top: 8px;
}

.inviteFilter {
  button {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    min-width: 75px;
  }
}
