@import "src/resources/styles/variables.scss";

@mixin mediaq($point) {
  @media (max-width: $point) {
    @content;
  }
}

@mixin minmediaq($point) {
  @media (min-width: $point) {
    @content;
  }
}

@mixin defaultFontStyle($fontfamily: null, $fontSize: null, $lineHeight: null) {
  font-family: $fontfamily;
  font-style: normal;
  font-size: ($fontSize) + px;
  line-height: ($lineHeight) + px;
}

@mixin fontSize($fontSize: null, $lineHeight: null, $fontWeight: null) {
  font-style: normal;
  font-size: ($fontSize) + px;
  line-height: ($lineHeight) + px;
  font-weight: ($fontWeight);
}

@mixin defaultFont($fontSize: null, $lineHeight: null) {
  font-style: normal;
  font-size: ($fontSize) + px;
  line-height: ($lineHeight) + px;
}

@mixin notificationColor($borderColor: null, $backgroundColor: null) {
  border-color: ($borderColor);
  background-color: ($backgroundColor);
}

@mixin flex($direction, $justify, $align) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
}

@mixin imgCircle($height, $width, $radius, $borerWidth) {
  border-radius: $radius;
  height: ($height) + px;
  width: ($width) + px;
  background-color: $color-light-yellow;
  border: ($borerWidth) + px dashed $color-yellow;
}
