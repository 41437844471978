@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

$color-yellow: #ffc629;
$color-black: #000000;
$color-light-black: #393939;
$color-icons-container: #ffeaae;
$color-bg: #f3f2ed;
$color-white: #fff;
$color-gray: #707070;
$color-gray-very-light: #f1f1f1;
$color-gray-light: #818181;
$color-blue: #7fbcf2;
$color-light-blue: #eef7ff;
$color-red: #f44336;
$color-light-red: #f36300;
$color-light-yellow: #fff8e4;
$color-dark-light-yellow: #ffd96f;
$color-blue-dark: #0080ff;
$color-blue-sm-dark: #5c88da;
$bg-white: #fff;
$color-warning: #fb9802;
$color-danger: #cd351f;
$color-error: #e00101;
$color-success: #16a646;
$color-dark-blue: #314c76;
// $regular: "Roboto", sans-serif;
$regular: "Outfit", sans-serif;
$gray-500: #4d4d4d;
$gray-300: #e0e0e0;
$gray-200: #f9f8f6;
$btn-border-gray: #e5e8ea;
$color-green: #13a26d;
$yellow-300: #fff1c9;
$orang-500: #ff8100;
