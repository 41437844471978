@import 'src/resources/styles/variables.scss';
@import 'src/resources/styles/mixins.scss';
.verification-title-wrapper {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // @include mediaq(991px) {
  //   flex-direction: column;
  // }
  .page-title {
    line-height: 79px;
    @include mediaq(991px) {
      line-height: 33px;
      margin-top: -28px;
      margin-left: 20px;
    }
  }
}
.verification-status {
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 27px;
  @include mediaq(991px) {
    margin-bottom: 15px !important;
    width: 100%;
    // margin-top: 7px;
  }
}
.verification-status-value {
  margin-left: 16px;
  padding: 3px 9px;
  color: $color-white;
  font-size: 13.84px;
  border-radius: 20px;
}
.status-unverified {
  background-color: #393939;
}
.status-pending {
  background-color: #f36300;
}
.status-verified {
  background-color: #13a26d;
}
.status-declined {
  background-color: #d32f2f;
}
.status-expired {
  background-color: #636569;
}

.alert-status-unverified {
  fill: #000000;
  path {
    fill: #000000;
  }
}
.alert-status-pending {
  fill: #f36300;
  path {
    fill: #f36300;
  }
}
.alert-status-verified {
  fill: #13a26d;
  path {
    fill: #13a26d;
  }
}
.alert-status-declined {
  fill: #d32f2f;
  path {
    fill: #d32f2f;
  }
}
.alert-status-expired {
  fill: #636569;
  path {
    fill: #636569;
  }
}
.verification-section {
  .section-header {
    display: flex;
    align-items: center;
    border-bottom: solid 1px $color-gray;
    margin-bottom: 16px;
    .icon {
      background-color: $color-yellow;
      height: 63px;
      width: 63px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h3 {
      margin-left: 20px;
    }
  }
  .notice {
    align-items: flex-start;
  }
  .verification-score-container {
    margin-top: 36px;
    .verification-score-conent {
      // margin-bottom: 9px;
      display: grid;
      grid-template-columns: 231px 231px;
      align-items: center;
      @media (max-width: 699px) {
        grid-template-columns: 231px;
        align-items: self-start;
        gap: 9px;
        margin-bottom: 18px;
      }
      .score-text {
        font-size: 23.4px;
        font-weight: 400;
      }
      .image-content {
        display: flex;
        margin-left: 45px;
        align-items: center;
        @media (max-width: 699px) {
          margin-left: 0px;
        }
        img {
          margin-right: 10px;
        }
      }
    }
    .verification-photo-id-conent {
      display: flex;
      margin-bottom: 36px;
    }
  }
  .verification-tabel-container {
    .tabel-header {
      font-size: 18px;
      font-weight: 500;
    }
    .tabel-doc-name {
      font-size: 18px;
      font-weight: 400;
    }
    .res-tabel-doc-name {
      color: #636569;
    }
  }
  .verification-choose-btn-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    margin-top: 18px;
  }
  .verification-choose-btn {
    border-radius: 8px;
    font-size: 12px;
    padding: 7px 14px;
    border: 0;
    margin-left: 2px !important;
    width: auto;
  }
  .verification-upload-img-name {
    display: flex;
    align-items: center;
    gap: 6px;
    &:hover {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
    .verified-icon {
      height: 13.85px;
      width: 13.85px;
    }
  }
  .green-color {
    color: #13a26d !important;
  }
  .app-button {
    @media (max-width: 699px) {
      width: 100%;
    }
  }
  .verification-error {
    margin-bottom: 9px;
    font-size: 13.84px;
    color: $color-error;
    font-weight: 400;
  }
}
// verification remove model
.verification-delete-modal {
  max-width: 613px;
  .model-title {
    font-size: 30.42px;
    font-weight: 500;
    line-height: 39.55px;
    margin-top: 3px;
    @media (max-width: 768px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
  .modal-content {
    padding: 27px;
    @media (max-width: 768px) {
      padding: 20px;
    }
  }
  .modal-body {
    padding: 0;
    .model-sub-text {
      margin-top: 39px;
      margin-bottom: 36px;
      font-size: 18px;
      font-weight: 400;
      @media (max-width: 768px) {
        margin-top: 29px;
        margin-bottom: 26px;
        font-size: 16px;
      }
    }
  }
  .verification-modal-btn {
    display: flex;
    gap: 10px;
    border-top: 1px solid #e0e0e0;
    margin: -27px;
    padding: 27px;
    margin-top: 27px;
    @media (max-width: 768px) {
      margin: -20px;
      padding: 20px;
      margin-top: 20px;
    }
    .cancel-btn {
      background-color: #393939;
      color: #ffffff;
      &:hover {
        background-color: #393939 !important;
        color: #ffffff !important;
      }
    }
  }
}

// verification created modal
.created-org-modal {
  .modal-body {
    .model-sub-text {
      margin-top: 18px;
    }
  }
  .verification-modal-btn {
    @media (max-width: 599px) {
      flex-direction: column;
      .main-button {
        &:last-child {
          margin-top: 0px;
        }
      }
    }
  }
}

.verification-button-view {
  z-index: 999;
  position: sticky;
  bottom: 0;
  background: $color-light-yellow;
  padding: 8px;
  margin-bottom: 60px;
  box-shadow: 0px 0px 6px #00000040;
  .app-button {
    border-radius: 3px;
    font-weight: 500;
    padding: 8px 15px;
  }
}
