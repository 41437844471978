@import "src/resources/styles/variables.scss";

.auction-lot-card {
  border: 1px solid $gray-300;
  border-radius: 18px;
  padding: 27px;
  background: $color-white;
  height: 100%;
  @media (max-width: 1300px) {
    padding: 18px;
  }
  .auction-lot-card-details {
    .auction-lot-img {
      height: 284.67px;
      background: $color-gray-very-light;
      border-radius: 9px;
      cursor: pointer;
      @media (max-width: 768px) {
        height: 210px;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 9px;
      }
    }
    .img-overlay {
      position: relative;
      .img-overlay-wrap {
        position: absolute;
        box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.45);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        border-radius: 9px;
        img {
          height: auto;
          max-width: 85px;
        }
      }
    }
    .aucton-lot-title {
      margin-top: 18.3px;
      margin-bottom: 6px;
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
      min-height: 50px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-all;
      overflow: hidden;
      @media (max-width: 768px) {
        margin-top: 9px;
      }
    }
    .aucton-lot-provided--by-title {
      font-size: 13.84px;
      line-height: 18px;
      margin-bottom: 18px;
      color: $color-gray-light;
    }
    .auction-lot-bid-title {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 6px;
      span {
        font-size: 18px;
        line-height: 23px;
      }
    }
    .auction-lot-winner-title {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 18px;
      color: $color-blue-dark;
      span {
        font-size: 18px;
        line-height: 23px;
      }
    }
    .bid-arrow {
      margin-left: 13px;
      svg {
        line-height: 23px;
        width: 15px;
        height: 7.5px;
      }
      &.rotate-arrow-180 {
        transform: rotate(180deg);
      }
      &.bid-down-arrow {
        svg {
          line-height: 23px;
          width: 15px;
          height: 7.5px;
          path {
            stroke: $color-white;
          }
        }
      }
    }
  }
}
