@import "src/resources/styles/variables.scss";
@import "src/resources/styles/mixins.scss";

.eventDetails {
  .event-card {
    cursor: pointer;
    .card_content {
      .event-date,
      .event-name,
      .time-left,
      .amount-raised {
        font-family: "Roboto";
      }
    }
  }
  .container {
    @media (max-width: 1400px) {
      //max-width: 1140px;
    }

    .square {
      height: 67px;
      width: 67px;
      border: 4px dotted $color-yellow;
      font-size: 10px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-yellow;
      margin: 0 auto;
    }
  }
  .addToCart {
    .card-body {
      background-color: $color-white;
    }
    .text-currency {
      font-size: 40px;
      line-height: 1.2;
    }
  }
  .addButtons {
    height: 45px;
    width: 45px;
    border-radius: 4px;
    border-color: $color-bg;
    background-color: $color-yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 28px;
  }
  .addButtonInput.featureCardinput {
    width: 100px;
    margin-left: 9px;
    margin-right: 9px;
    @media (max-width: 599px) {
      width: 80%;
    }
  }
  .addButtonInput {
    border-color: $color-gray;
    height: 45px;
    width: 52px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 4px;
    text-align: center;
    @media (max-width: 599px) {
      width: 80%;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .carousel {
    .carousel-inner {
      /*  background-color: $color-gray-very-light;
      min-height: 100vh; */
      text-align: center;
      display: flex;
      align-items: center;
      border-radius: 5px;
      width: 100%;
      height: auto;
      // width: 1050px;
      // height: 700px;
      @media (max-width: 599px) {
        width: 100%;
        height: 100%;
      }

      img {
        // width: 1050px;
        // height: 700px;
        width: 100%;
        height: auto;
        aspect-ratio: 3 / 2;
        @media (max-width: 599px) {
          width: 100%;
          height: 100%;
        }
        :hover {
          background-color: transparent;
        }
      }
    }

    .carousel-indicators li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 1px solid #ffc629;
      opacity: 1;
    }
    .carousel-indicators .active {
      background-color: #ffc629;
      opacity: 1;
    }

    .carousel-control-prev-icon {
      background-image: url(../../resources/images/prevIcon.png);
      opacity: 1;
      width: 20px;
      height: 30px;
    }
    .carousel-control-next-icon {
      background-image: url(../../resources/images/nextIcon.png);
      opacity: 1;
      width: 20px;
      height: 30px;
    }
  }
  .slick-slide {
    .wonItemPrizeCard {
      margin: 10px;
      img {
        aspect-ratio: 3 / 2;
      }
      .card {
        border-radius: 5px;
        padding: 0;
        img {
          @media (max-width: 599px) {
            margin: 0 auto;
            width: 236px;
            height: 103.61px;
          }
        }

        .card-body {
          padding: 15px;
          background-color: $color-white;
          @media (max-width: 599px) {
            padding: 10.39px;
            .card-title {
              font-size: 14px;
              line-height: 21px;
              color: #000;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .slick-list {
    @media (max-width: 599px) {
      overflow-x: scroll;
    }
  }
  .slick-track {
    @media (max-width: 599px) {
      display: inline-flex;
      overflow-x: scroll;
    }
  }
  .priceCircle {
    .circle {
      width: 204px;
      min-width: 204px;
      min-height: 204px;
      border-radius: 50%;
      border: 10px solid $color-yellow;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-light-yellow;
      @media (max-width: 1279px) {
        width: 170px;
        min-width: 170px;
        min-height: 170px;
      }
    }

    h2 {
      font-size: 35px;
      word-break: break-word;
      padding: 1px;
      span {
        font-size: 35px;
        word-break: break-word;
        padding: 1px;
        @media (max-width: 379px) {
          font-size: 32px;
        }
      }
    }

    input {
      border-radius: 3px;
    }

    ::placeholder {
      color: $color-gray-light;
    }
  }
  .event-details-auction {
    img {
      transition: all 0.1s 0.1s;
    }
    .closed {
      transform: rotate(180deg);
    }
    .search {
      @media (max-width: 599px) {
        text-align: center;
        margin: 0 12px;
      }
      .search-button {
        @media (max-width: 599px) {
          width: 100%;
          margin: 15px 0;
        }
      }
      @media (max-width: 539px) {
        .input-group {
          display: block;
        }
        .input-group-append {
          width: 100%;
        }
        .filter-dropdown {
          border-right: 1px solid black !important;
          padding: 17px 10px;
          margin-bottom: 15px;
        }
        input {
          width: 100%;
        }
      }
      .input-group-append {
        &.show {
          transition: all 0.5s ease-in-out;

          img {
            transform: rotate(180deg);
            transition: all 0.5s ease-in-out;
          }
        }

        img {
          float: right;
          position: relative;
          top: 10px;
        }
      }

      .filter-dropdown {
        border-right: 0;
      }

      input {
        font-size: 16px;
      }
    }

    .card {
      .imgWrapper {
        height: 278px;
        display: flex;
        align-items: center;
        background: #f1f1f1;
        border-radius: 5px;
        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .card-body {
        background-color: $color-white;
      }
    }
  }
  .eventDetailsTab {
    .auctionWallPlatinumSponsor {
      &:nth-child(1) {
        .sponsor-box {
          font-size: 32px;
          width: 300px;
          margin: 18px;
          height: 100%;
          img {
            width: 100%;
            height: 320px;
          }
          @media (max-width: 599px) {
            min-width: auto;
            min-height: auto;
            img {
              width: 100%;
              height: 320px;
            }
          }
        }
      }

      &:nth-child(2) {
        .sponsor-box {
          font-size: 28px;
          width: 250px;
          height: 100%;

          margin: 18px;
          img {
            width: 100%;
            height: 298px;
          }
        }
      }

      &:nth-child(3) {
        .sponsor-box {
          font-size: 27px;
          width: 200px;
          height: 100%;

          margin: 18px;
          img {
            width: 100%;
            height: 220px;
          }
        }
      }
      &:nth-child(4) {
        .sponsor-box {
          font-size: 28px;
          width: 150px;
          height: 100%;

          margin: 18px;
          img {
            width: 100%;
            height: 170px;
          }
        }
      }
      .sponsor-box {
        border: none;
        font-size: 28px;
        width: 150px;
        height: 100%;
        margin: 18px;
        img {
          border-radius: 5px;
          border: 1px solid #707070;
          object-fit: cover;
          width: 100%;
          height: 170px;
        }
      }
    }

    .border-bottom {
      &:last-child {
        border-bottom: none !important;
        padding-bottom: 0 !important;
      }
    }
  }
}

.qr-modal {
  @media (max-width: 599px) {
    width: 95%;
  }
}
.model-close {
  position: absolute;
  cursor: pointer;
  right: 17px;
  top: 24px;
  z-index: 99999;
}
.bidding-count {
  .addButtons {
    height: 45px;
    width: 45px;
    border-radius: 4px;
    border-color: $color-bg;
    background-color: $color-yellow;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 28px;
  }

  .addButtonInput {
    border-color: $color-gray;
    height: 45px;
    width: 45px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 4px;
    text-align: center;
  }

  .btn {
    min-height: 60px;
  }
}
.span-style {
  position: absolute;
  z-index: 11;
  top: 12px;
  left: 15px;
  padding-right: 20px;
}
.input_field-style {
  padding-left: 60px;
  width: 100% !important;
}
.logout-modal {
  max-width: 570px;
  text-align: center;
}
.res-org-tickets {
  .wonItemPrizeCard {
    .card {
      img {
        aspect-ratio: 3 / 2;
        object-fit: cover;
      }
    }
  }
  @media (max-width: 599px) {
    padding: 0 15px;

    .slick-track,
    .slick-slide {
      width: auto !important;
    }
  }
}
.ticket-purchase-modal {
  // width: 940px;
  width: 96%;
  .ticketBundleTab {
    img {
      transition: all 0.5s ease-in-out;
      &.down {
        transform: rotate(180deg);
        transition: all 0.5s ease-in-out;
      }
    }
  }
}
.event-wrapper {
  @media (max-width: 768px) {
    margin-bottom: 18px;
  }
}
.ticket-data-modal {
  width: 90%;
  max-width: 1500px;
  @media (max-width: 599px) {
    width: 96%;
    .close-rs-ticketmodal {
      position: absolute;
      right: 0;
      z-index: 11;
    }
  }
  p {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 12px;
  }
  h4 {
    font-size: 14px;
    font-weight: 500;
    color: $color-blue-dark;
  }
}
.discount-accordion {
  .ticketAmount {
    padding-left: 15px !important;
  }
}
.blur-section {
  opacity: 0.7;
}
.event-ticket-main {
  .blur-section {
    opacity: 1;
    pointer-events: none;
    // padding: 10px;
    // background: #9e9e9e1f;
    // background: #0000004d;
    // position: relative;
    // z-index: 111;
    // padding: 15px;
    // border-radius: 5px;
    h3 {
      position: absolute;
      text-align: center;
      width: 100%;
    }
    .border-bottom {
      opacity: 0.45;
    }
  }
  .ticket-item {
    padding-bottom: 25px;
    border-bottom: 1px solid #707070;
    margin-bottom: 20px;
  }
}
.slick-prev {
  left: -20px !important;
}
.slick-next {
  right: -20px !important;
}
.slick-prev:before,
.slick-next:before {
  color: #0b0000 !important;
}
.res-orgcard-name {
  color: #000;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  padding-bottom: 4px;
}
.res-orgby {
  font-size: 12px;
  color: #000;
}
.res-clloca-icon {
  margin-right: 20.03px;
  img {
    @media (max-width: 599px) {
      width: 21.75px;
    }
  }
}
.plusonlineevnt {
  padding-left: 2rem;
}
.text-amount {
  span {
    font-style: normal;
    font-size: 25px;
    line-height: 30px;
  }
  @media (max-width: 599px) {
    span {
      font-size: 20px;
    }
  }
}
.res-evnt-org-name {
  @media (max-width: 599px) {
    font-size: 18px;
    font-weight: 500 !important;
    color: #000;
    line-height: 24px;
    padding-top: 6px;
  }
}
.res-raffale-prz,
.res-org-about,
.res-org-donation-amt {
  @media (max-width: 767px) {
    flex-direction: column-reverse;
  }
  @media (max-width: 599px) {
    padding: 0px 15px;
  }
}
.res-org-about {
  p {
    word-break: break-word;
  }
}
.res-navtabbar-scroll {
  @media (max-width: 599px) {
    flex-wrap: inherit !important;
    overflow-x: scroll;
  }
}
.res-ticketlist-display {
  display: flex;
  justify-content: space-between;
  .res-ticket-data {
    .rst-price {
      font-size: 1.25rem;
      color: #000;
      line-height: 21px;
      font-weight: 400;
    }
  }
  @media (max-width: 599px) {
    display: block;
    .res-ticket-data {
      .rst-name {
        font-size: 16px;
        line-height: 21px;
        color: #000;
        font-weight: 600;
      }
      .rst-price {
        font-size: 16px;
        color: #000;
        line-height: 21px;
        font-weight: 400;
      }
      .rst-desc {
        margin-top: 15px;
        margin-bottom: 30px;
        color: #000;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
.default-color {
  background-color: $color-white;
}
.rst-tckt-mdl {
  @media (max-width: 599px) {
    padding: 0 15px;
  }
}
.renderhtml-main {
  br {
    display: none;
  }
}
.follow-msg-block {
  width: 60%;
  left: 20%;
  z-index: 999;
  position: absolute;
  top: 80px;
  text-align: center;
}
.direction-reverse.row {
  flex-direction: unset;
}
.p-15 {
  padding: 15px;
}
.show-on-mobile {
  display: none;
}
.ticket-print-main {
  .res-clloca-icon {
    display: none;
  }
  .media {
    margin-bottom: 0 !important;
  }
  .event-details {
    padding: 20px 0;
  }
  .site-logo {
    img {
      width: 150px;
    }
  }
  h3 {
    font-size: 22px;
    font-weight: bold;
  }
  p {
    font-size: 17px;
    font-weight: 500;
    padding-bottom: 5px;
  }
  .ticket-table {
    margin-top: 20px;
    // border: 2px solid #dad7d7;
    // border-bottom: 0;
  }
  .table-headmain,
  .ticket-item {
    padding: 10px;
    border: 2px solid #dad7d7;
    // border-bottom: 0;
  }
  // .ticket-table div:last-child {
  //   border-bottom: 2px solid #dad7d7;
  // }
  .table-headmain {
    font-weight: bold;
    background: #f2f2f2;
  }
  .ticket-item {
    height: 95px;
    page-break-after: inherit;
    // auto|always|avoid|left|right|initial|inherit;
  }
  .ticket-footer {
    p {
      font-weight: unset;
    }
    a {
      font-size: 18px;
      color: black;
      font-weight: bold;
    }
  }
}
.search {
  .search-custom-dropdown {
    .search-custom-dropdown-btn {
      border-radius: 3px 0px 0px 3px !important;
      border: 1px solid #000000 !important;
      @media (max-width: 539px) {
        border-radius: 3px !important;
      }
    }
    .search-custom-field {
      border: 1px solid #000000 !important;
      border-radius: 0px 3px 3px 0px !important;
      @media (max-width: 539px) {
        border-radius: 3px !important;
      }
    }
  }
}
.swiper-slide {
  text-align: center;
}
.add-price-modal {
  .app-button {
    width: 33%;
  }
}
.no-private-event-access {
  min-height: 527px;
  height: 100%;
  text-align: center;
  align-content: center;
  @media (max-width: 768px) {
    min-height: 358px;
    background-color: transparent !important;
    box-shadow: none !important;
    border-bottom: 2px solid #fff;
  }
  .no-private-event-title {
    font-size: 39.55px;
    line-height: 50px;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 23.4px;
      line-height: 29px;
    }
  }
  .no-private-event-sub-title {
    font-size: 18px;
    line-height: 23px;
    padding-bottom: 27px;
    max-width: 988px;
    margin: 0 auto;
  }
  .no-private-event-btn {
    font-size: 18px;
    line-height: 23px;
    padding: 13px 26px;
  }
}

// Update UI
div,
p {
  word-break: break-word;
}
.event-details-wrapper {
  padding-top: 57px;
  @media (max-width: 999px) {
    padding-top: 18px;
  }
  .container {
    padding: 0px 7px;
    overflow-x: hidden;
  }
}
:root {
  --right-side-width: 476px;
  --event-side-gap: 36px;
  @media (max-width: 1200px) {
    --right-side-width: 360px;
  }
}
.event-details-container {
  background-color: $color-white;
  display: flex;
  align-items: flex-start;
  gap: 36px;
  border-radius: 18px;
  padding: 5px;
  @media (max-width: 999px) {
    flex-direction: column;
  }
  @media (max-width: 599px) {
    gap: 45px;
  }
}
.event-left-side-container {
  width: calc(100% - var(--right-side-width) - var(--event-side-gap));
  @media (max-width: 999px) {
    width: 100%;
  }
  .event-image-wrapper {
    height: 433.76px;
    background-color: $color-gray-very-light;
    border-radius: 18px;
    // @media (max-width: 1024px) {
    //   height: 100%;
    // }
    @media (max-width: 599px) {
      height: 154.1px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 18px;
    }
  }
  .event-name-text {
    font-size: 39.55px;
    line-height: 48.5px;
    font-weight: 600;
    margin-top: 27.2px;
    margin-bottom: 36px;
    @media (max-width: 599px) {
      font-size: 23.4px;
      line-height: 29px;
      margin-top: 18.9px;
      margin-bottom: 27px;
    }
  }
  // event count down and amount raised
  .event-count-down-details {
    background: $gray-200;
    padding: 36px;
    border-radius: 9px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    @media (max-width: 1300px) {
      gap: 27px;
      padding: 27px 18px;
    }
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 999px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    .event-count-time-title {
      font-size: 23.4px;
      line-height: 29px;
      font-weight: 500;
      margin-bottom: 9px;
      color: $color-black;
      @media (max-width: 599px) {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 6px;
      }
    }
    .event-count-time-value {
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
      color: $color-blue-dark;
      span {
        font-size: 18px;
        line-height: 23px;
        font-weight: 500;
        color: $color-blue-dark;
      }
    }
    .event-close-text {
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
      color: $color-green;
    }
    .total-amt-raised-slider {
      width: calc(100% - 42px);
      padding: 0;
      margin-top: 18px;
      @media (max-width: 1300px) {
        width: 100%;
      }
      &.Mui-disabled {
        color: $color-blue-dark;
      }
      .MuiSlider-rail {
        background-color: $gray-300;
        opacity: 1;
      }
      .MuiSlider-track,
      .MuiSlider-rail {
        height: 9px;
      }
      .MuiSlider-thumb {
        display: none;
      }
    }
  }
}
.event-right-side-container {
  width: var(--right-side-width);
  @media (max-width: 999px) {
    width: 100%;
  }
  .event-qr-code-container {
    outline: 1px solid $gray-300;
    border-radius: 18px;
    padding: 36px;
    background: $color-white;
    @media (max-width: 599px) {
      padding: 27px 18px;
    }
    .event-qr-wrap {
      display: flex;
      .qr-content {
        @media (max-width: 599px) {
          align-content: center;
        }
        .qr-head {
          font-size: 23.4px;
          line-height: 29px;
          margin-bottom: 3px;
          font-weight: 500;
        }
        .qr-larg-view-link {
          font-size: 18px;
          line-height: 23px;
          color: $color-blue-dark;
          text-decoration: underline;
          text-underline-offset: 2px;
          cursor: pointer;
        }
      }
    }
    .social-media-main {
      display: flex;
      gap: 9px;
      button {
        color: #ffffff;
        padding: 0;
        height: 38px;
        width: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .copy_link_button {
      line-height: 15px;
    }
    .event-save-add-btn {
      margin-top: 27px;
      .event-save-btn {
        margin-bottom: 10px;
        @media (max-width: 599px) {
          margin-bottom: 9px;
        }
        svg {
          color: #1d1d1d;
          margin-right: 4.38px;
        }
        span {
          font-size: 18px;
          line-height: 23px;
        }
      }
    }
  }
  .event-org-details-container {
    outline: 1px solid $gray-300;
    border-radius: 18px;
    padding: 36px;
    background: $color-white;
    margin-top: 35px;
    @media (max-width: 599px) {
      padding: 27px 18px;
      margin-top: 18px;
    }
    .event-org-logo-name {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      @media (max-width: 599px) {
        flex-direction: column;
      }
      .event-org-image {
        .imgWidth {
          height: 99px;
          width: 99px;
          border-radius: 9px;
          @media (max-width: 599px) {
            height: 109px;
            width: 109px;
          }
        }
      }
      .event-org-name {
        font-size: 23.4px;
        line-height: 29px;
        font-weight: 500;
        @media (max-width: 599px) {
          margin-top: 18px;
          margin-left: 0px;
        }
      }
    }
    .event-org-social-icon-container {
      .social-icon-wrap {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: start;
        align-items: center;
        gap: 12px;
        @media (max-width: 599px) {
          gap: 10.1px;
          justify-content: center;
          svg {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
    .event-org-description {
      font-size: 18px;
      line-height: 27px;
      font-weight: 400;
      margin-top: 27px;
      @media (max-width: 599px) {
        margin-top: 18px;
      }
    }
  }
}
.event-about-container {
  margin-top: 45px;
}
.event-time-venu-details {
  display: flex;
  flex-direction: column;
  gap: 36px;
  .event-time-details-wrapper {
    display: inline-flex;
    .event-time-detail-icon {
      height: 61px;
      width: 61px;

      @media (max-width: 599px) {
        height: 45px;
        width: 45px;
      }
    }
    .event-time-details-content {
      padding-left: 22px;
      @media (max-width: 599px) {
        padding-left: 18px;
      }
      .event-time-details-heading {
        .event-time-details-title {
          font-size: 23.4px;
          line-height: 29px;
          font-weight: 500;
          margin-bottom: 9px;
          @media (max-width: 599px) {
            font-size: 18px;
            line-height: 23px;
          }
          .question-icon {
            font-size: 13px;
          }
        }
        .event-time-details-value {
          font-size: 18px;
          line-height: 23px;
          color: $gray-500;
          span {
            font-size: 18px;
            line-height: 23px;
            color: $gray-500;
          }
        }
        .event-time-details-venu-title {
          font-size: 18px;
          line-height: 23px;
          font-weight: 500;
          margin-bottom: 9px;
        }
      }
    }
  }
}
.event-about-details {
  margin-top: 63px;
  @media (max-width: 599px) {
    margin-top: 45px;
  }
  .event-about-title {
    font-size: 23.4px;
    line-height: 29px;
    font-weight: 500;
    @media (max-width: 599px) {
      margin-bottom: 9px;
    }
  }
  .event-about-description {
    font-size: 18px;
    line-height: 27px;
    color: $gray-500;
  }
}
.event-details-bottom-divider {
  margin-top: 54px;
  margin-bottom: 54px;
  border: 2px solid $color-bg;
  opacity: 1;
}
.collapse-closed {
  transform: rotate(180deg);
}
// similer event
.similar-event-container {
  padding: 0px 5px 90px 5px;
  .similar-event-title {
    font-size: 30.42px;
    line-height: 38px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 27px;
    @media (max-width: 599px) {
      font-size: 23.4px;
      line-height: 29px;
    }
  }
}
