@import 'src/resources/styles/variables.scss';
@import 'src/resources/styles/mixins.scss';

.event-section {
  .notice {
    align-items: flex-start;
  }
}

.card-details {
  .expColor {
    color: #888b8d;
  }

  .cardInfo {
    border: 1px solid $color-gray;
    border-radius: 3px;
    padding: 10px;
    min-height: 171px;

    .visaBox {
      padding: 35px 30px;
      align-items: center;

      img {
        min-width: 90px;
        margin-right: 30px;
      }
    }
  }

  .dottedBox {
    min-height: 171px;
  }

  .form-group {
    ::placeholder {
      color: $color-black !important;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $color-black;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: $color-black;
    }
  }
  @include mediaq(991px) {
    padding-left: 0 !important;
  }

  .page-title-block {
    @include mediaq(991px) {
      margin-bottom: 27px !important;
    }
  }
  .page-title {
    @include mediaq(991px) {
      margin-top: -50px;
      z-index: 0;
      margin-bottom: 29px !important;
      line-height: 33px;
      font-size: 30px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    @include mediaq(599px) {
      font-size: 25px;
    }
  }
  .cardBlock {
    @include mediaq(991px) {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
  .primary-card-text {
    font-size: 13.84px;
    font-weight: bold;
  }
  .make-primary-card-text {
    font-size: 13.84px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 2px;
    color: $color-black;
  }
}
.bankDetails .page-title {
  @media (max-width: 991px) {
    margin-top: 0px !important;
    z-index: 0;
    margin-bottom: 36px !important;
    line-height: 33px;
    font-size: 30px;
    text-align: center;
  }
  @media (max-width: 599px) {
    font-size: 25px;
  }
}
.delete-card-modal {
  .modal-body {
    .model-sub-text {
      margin-top: 18px;
    }
    .verification-modal-btn {
      @media (max-width: 425px) {
        flex-direction: column;
      }
    }
  }
}

.card-notes-sub-text {
  font-size: 12px !important;
  font-weight: 400 !important;
}
.notice {
  .collapse-icon {
    height: 10px;
    width: 15px;
    transition: all 0.5s ease-in-out;
    &.down {
      transform: rotate(180deg);
      transition: all 0.5s ease-in-out;
    }
  }
}
