@import "src/resources/styles/variables.scss";
body {
  .event-details-raffle-feature {
    margin-top: 45px;
    .feature-raffle-prize-title {
      font-size: 23.4px;
      line-height: 29px;
      font-weight: 500;
      margin-bottom: 18px;
    }
    .raffle-winner-prize-container {
      .raffle-winner-prize-details {
        margin-top: 27.3px;
        @media (max-width: 768px) {
          margin-top: 27px;
        }
        .raffle-winner-prize-title {
          font-size: 18px;
          line-height: 23px;
          font-weight: 500;
          margin-bottom: 18px;
        }
      }
      .raffle-winner-prize-provided-section {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 599px) {
          flex-direction: column;
          align-items: flex-start;
          gap: 18px;
        }
        .raffle-winner-prize-provided {
          font-size: 18px;
          line-height: 23px;
          color: $gray-500;
        }
      }
      .raffle-winner-prize-about {
        font-size: 18px;
        line-height: 27px;
        color: $gray-500;
      }
      .raffle-prize-bottom-divider {
        margin-top: 45px;
        margin-bottom: 45px;
        border: 2px solid $color-bg;
        opacity: 1;
      }
    }
  }
  .event-details-raffle-tickets-print-container {
    margin-top: 63px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 18px;
    .event-details-raffle-tickets-print-title {
      font-size: 23.4px;
      line-height: 29px;
      font-weight: 500;
    }
    .event-details-raffle-tickets-print-link {
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
      text-decoration: underline;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .your-raffle-tickets-right-shadow {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 15px; /* Width of the gradient */
      height: calc(100% - 32px);
      background: linear-gradient(to left, rgba(255, 255, 255, 1), transparent);
    }
  }
  .your-raffle-tickets-container {
    display: inline-flex;
    justify-content: start;
    align-items: center;
    gap: 9.1px;
    overflow: hidden;
    overflow-x: scroll;
    width: 100%;
  }
  .your-raffle-tickets-wrapper {
    background: $color-white;
    border: 1px solid $gray-300;
    border-radius: 8px;
    width: 249px;
    min-width: 249px;
    height: 158px;
    margin-bottom: 18px;
    .your-raffle-tickets-img-wrapper {
      height: 109.29px;
      background: $color-gray-very-light;
      border-radius: 8px 8px 0px 0px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 8px 8px 0px 0px;
      }
    }
    .your-raffle-tickets-number {
      font-size: 18px;
      line-height: 23px;
      padding: 12.7px 13px 13px 13px;
    }
  }
  .your-raffle-tickets-scroll {
    /* Scrollbar for Webkit-based browsers (Chrome, Edge, Safari) */

    &::-webkit-scrollbar {
      height: 13px; /* For horizontal scrollbar */
      width: 13px; /* For vertical scrollbar */
    }

    &::-webkit-scrollbar-track {
      border: 1px solid #cdcdcd;
      background: $color-bg; /* Light gray track background */
      border-radius: 10px; /* Rounded edges for track */
    }

    &::-webkit-scrollbar-thumb {
      background: $color-white; /* Slightly darker thumb color */
      border-radius: 10px; /* Rounded edges for thumb */
      border: 1px solid #9b9b9b;
      background-image: url("../../../../../public//scrollbar_icon.svg"); /* Add your icon here */
      background-repeat: no-repeat; /* Prevent icon repetition */
      background-position: center; /* Center the icon inside the thumb */
      background-size: 6px 7px; /* Adjust the icon size */
    }
  }
  .event-details-raffle-tickets-container {
    margin-top: 45px;
    .feature-raffle-tickets-title {
      font-size: 23.4px;
      line-height: 29px;
      font-weight: 500;
      margin-bottom: 17px;
    }
    .raffle-tickets-add-amount-card-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 26px;
      @media (max-width: 999px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 599px) {
        grid-template-columns: 1fr;
      }
      .raffle-tickets-amount-card {
        background: $color-white;
        padding: 27px;
        border-radius: 18px;
        border: 1px solid $gray-300;
        @media (max-width: 1400px) {
          padding: 18px;
        }
        .raffle-tickets-amount-title {
          font-size: 18px;
          line-height: 23px;
          font-weight: 500;
          margin-bottom: 18px;
        }
        .raffle-tickets-amount-value {
          margin-bottom: 9px;
          @media (max-width: 768px) {
            margin-bottom: 18px;
          }
          span {
            font-size: 30.42px;
            line-height: 38px;
          }
        }
        .raffle-tickets-quantity {
          font-size: 18px;
          line-height: 23px;
          margin-bottom: 27px;
        }
        .raffle-tickets-card-amount-update-btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 18px;
          // flex-wrap: wrap;
          @media (max-width: 1300px) {
            flex-direction: column;
            align-items: normal;
          }
          .raffle-tickets-update-count-btn {
            min-width: 49px;
            height: 49px;
          }
          .raffle-tickets-count-input-field {
            max-width: 67px;
            margin: 0px 9px;
            @media (max-width: 1300px) {
              max-width: 100%;
              width: 100%;
            }
            .raffle-tickets-count-field {
              .MuiInputBase-input {
                text-align: center;
              }
              .MuiOutlinedInput-notchedOutline {
                top: -10px;
              }
            }
          }
          .raffle-tickets-add-to-cart-btn {
            padding: 13px 26px;
            min-width: 148px;
            width: 100%;
          }
        }
      }
    }
  }
  .sticky-btn-bg {
    position: fixed;
    bottom: 0px;
    z-index: 999;
    left: 0;
    right: 0;
    background: $yellow-300;
    padding: 9px;
    text-align: center;
    button {
      padding: 12px 24px;
    }
  }

  // raffle tickets modal
  .event-details-raffle-ticket-modal {
    max-width: 352px;
    background: $color-white;
    border-radius: 9px;
    margin: 20px auto;
    .modal-content {
      padding: 18px;
      border: none;
      .modal-body {
        padding: 0px;
        .modal-your-raffle-tickets-wrapper {
          border-radius: 8px;
          width: 316px;
          min-width: 316px;
          margin-top: 18px;
          .modal-your-raffle-tickets-img-wrapper {
            height: 138.73px;
            background: $color-gray-very-light;
            border-radius: 8px;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 8px;
            }
          }
          .modal-your-raffle-tickets-details {
            padding-top: 27px;
            padding-bottom: 9px;
            display: flex;
            flex-direction: column;
            gap: 23px;
            .modal-your-raffle-tickets-title {
              font-size: 13.84px;
              line-height: 18px;
              color: $color-gray-light;
              padding-bottom: 6px;
            }
            .modal-your-raffle-tickets-value {
              font-size: 23.4px;
              line-height: 29px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }

  // raffle ticket print view
  .raffle-tickets-print-view-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 552px));

    .raffle-tickets-print-wrapper {
      background: $color-white;
      border: 1px solid $gray-300;
      padding: 45px 27px;
      .raffle-tickets-print-details {
        margin-top: 54px;
        &:first-child {
          margin-top: 0px;
        }
        .raffle-tickets-print-title {
          font-size: 30.42px;
          line-height: 38px;
          margin-bottom: 9px;
          color: $color-gray;
        }
        .raffle-tickets-print-value {
          font-size: 51.41px;
          line-height: 64px;
        }
      }
    }
  }
}
