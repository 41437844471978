@import "./variables.scss";
@import "./mixins.scss";

* {
  outline: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 60px;
  @include mediaq(991px) {
    font-size: 40px;
  }
  @include mediaq(767px) {
    font-size: 30px;
  }
}

h2 {
  font-size: 40px;
  @include mediaq(991px) {
    font-size: 30px;
  }
  @include mediaq(767px) {
    font-size: 26px;
  }
}

h3 {
}

h4 {
  @include fontSize(25, 30);
  @include mediaq(991px) {
    @include fontSize(20, 26);
  }
  @include mediaq(767px) {
    @include fontSize(18, 24);
  }
}

a,
p {
  @include defaultFontStyle($regular, 18, 23);
  @include mediaq(991px) {
    @include defaultFontStyle($regular, 16, 20);
  }
  @include mediaq(767px) {
    @include defaultFontStyle($regular, 12, 18);
  }
}

span {
  @include defaultFontStyle($regular, 16, 20);
}

a:hover {
  text-decoration: none;
  color: inherit;
}

section {
  padding: 0px;
}

.container {
  max-width: 1524px;
  width: 100%;
}

.container_1200 {
  max-width: 1200px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

input:focus,
button:focus {
  outline: none;
  box-shadow: none;
}

// .form-group {
//   margin-bottom: 0;
// }
.focus_field {
  label {
    @include defaultFont(11, 13);

    &.form-check-label {
      @include defaultFont(16, 19);
    }
  }
}

.form-check-label {
  color: $color-black;
}

.form-check-input {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 0px;
  border-color: $color-yellow !important;
  &:checked {
    background-color: $color-yellow;
  }
  &:focus {
    box-shadow: none;
  }
}

.form-check-input:checked ~ .form-check-label::before,
.form-check-input:not(:disabled):active ~ .form-check-label::before {
  border-color: $color-yellow;
  background-color: $color-yellow !important;
  cursor: pointer;
}

.custom-checkbox .form-check-label::before {
  border-radius: 3px;
  top: 2px;
  left: -27px;
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.form-check-label::after {
  height: 20px;
  width: 20px;
  top: 2px;
  left: -26px;
  cursor: pointer;
}

.custom-checkbox .form-check-input:checked ~ .form-check-label::after {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAJCAYAAADkZNYtAAAABHNCSVQICAgIfAhkiAAAAH1JREFUGFeF0NENQDAUheG/GxjFBhihE7CKCRiBSRiBTdhATnKvtCT0RTTfuadt4H+VwADE8GMFF6AA+i+cwhnoHCt5JC3610QFdvsi7BMaYLPKFNY+SHgFKtuIdhmfeEO1CqtyBNrkGKrOoGM3kwVOgzpStp6voQaFXlCpC9sFGZoVxI7MAAAAAElFTkSuQmCC") !important;
}

.form-check-label::before {
  border-color: $color-yellow;
}

.popover {
  max-width: 400px;

  .popover-body {
    box-shadow: 1px 1px 7px 2px #ddd;

    p {
      color: $color-black;
      font-size: 12px;
      font-weight: normal;

      &:first-child {
        font-weight: 500;
      }
    }
  }
}

@include mediaq(1200px) {
  .container {
    max-width: 1500px;
  }
}

.fixed-fieldset {
  input,
  textarea {
    box-shadow: none;
    & + .label-helper {
      top: -10px;
      bottom: auto;
      font-family: Roboto;
      line-height: 1;
      opacity: 1;
      padding: 4px;
      z-index: 9;
      background: #fff;
      left: 10px;
      color: #707070;
    }
  }
  input,
  textarea {
    background-color: white;
    -webkit-appearance: none;
    // border: 1px solid #cccccc;
    font-family: Roboto;
    margin-bottom: 8px;
    // padding: 9px 15px;
    position: relative;
    width: 100%;
    z-index: 3;
  }
}
.focus_field {
  // margin-bottom: 20px;
  // padding: 4px 0;
  position: relative;

  input,
  textarea {
    background-color: white;
    -webkit-appearance: none;
    // border: 1px solid #cccccc;
    font-family: Roboto;
    margin-bottom: 8px;
    // padding: 9px 15px;
    position: relative;
    width: 100%;
    z-index: 3;
  }
  .set-fix-lable {
    color: $color-yellow;
  }

  input:focus,
  textarea:focus {
    border-color: $color-yellow !important;
    box-shadow: none;

    & + .label-helper {
      color: $color-yellow;
    }
  }

  .label-helper {
    position: absolute;
    opacity: 0;
    // transition: 0.2s bottom, 0.2s opacity;
    transition: all 0.2s ease-out;
    left: 15px;
    z-index: 1;
    top: 0;
    bottom: 0;
    // .label-helper:focus-within label {
    //   transform: translate(0, 12px) scale(0.75);
    // }
  }

  input:focus + .label-helper,
  input:invalid + .label-helper,
  textarea:focus + .label-helper,
  textarea:invalid + .label-helper,
  select:focus + .label-helper,
  select:invalid + .label-helper {
    top: -10px;
    bottom: auto;
    font-family: Roboto;
    line-height: 1;
    opacity: 1;
    padding: 4px;
    z-index: 9;
    background: #fff;
    left: 10px;
  }
}

.auth_field_wrap {
  text-align: center;
  .form-group,
  .btn-block {
    text-align: start;
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    .character_remain {
      @include defaultFont(12, 14);
      font-weight: 500;
    }
  }
  .btn-block {
    text-align: center;
  }
  .focus_field {
    margin-bottom: 18px !important;
  }
}

.form-group.mb-0 {
  margin-bottom: 0px !important;
}

.form-group {
  .character_remain {
    @include fontSize(12, 14, 500);
    margin-top: 5px;
  }

  .css-1rhbuit-multiValue {
    border-radius: 25px;
    background: $color-yellow;

    .css-xb97g8 {
      cursor: pointer;

      svg {
        background: $color-black;
        color: $color-yellow;
        border-radius: 50%;
      }

      &:hover {
        background: none;
      }
    }
  }

  button {
    font-size: 20px;
    border-radius: 3px;
  }

  .form-check {
    display: flex;
    align-items: center;
    // .form-check-label {
    //   text-decoration: underline;
    // }
  }
}

.bs-popover-auto[x-placement^="right"],
.bs-popover-right {
  margin-left: 15px;
}

.col-sm-12 {
  position: relative;
}
.input-group-append {
  display: flex;
}
