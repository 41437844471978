@import "src/resources/styles/variables.scss";
@import "src/resources/styles/override.scss";
@import "src/resources/styles/common.scss";
@import "src/resources/styles/mixins.scss";

body {
  font-size: 16px;
  font-family: $regular;
  color: $color-black;
  padding: 0;

  .select__control {
    border-color: $gray-300;
    border-radius: 8px;
    min-height: auto;
    max-width: 400px;
    cursor: pointer;
    box-shadow: none;
    padding: 12px 13px 11px 12px;
    &:hover {
      border-color: #ffc629;
    }
    &:focus {
      border-color: #ffc629;
    }
    @media (max-width: 479px) {
      max-width: none;
    }

    .select__value-container {
      padding: 0px;
    }

    .select__single-value {
      font-family: $regular;
      font-size: 16px;
      line-height: 20px;
      color: $color-black;
      font-size: 16px;
      top: 17px;
    }

    .select__placeholder {
      font-family: $regular;
      font-size: 16px;
      line-height: 20px;
      color: $color-gray-light;
      padding: 0px 6px 0px 0px;
      margin: 0px;
    }

    .select__input-container {
      font-family: $regular;
      font-size: 16px;
      line-height: 20px;
      color: $color-black;
      padding: 0px 8px;
      margin: 0px;
    }

    .select__indicator-separator {
      width: 0px;
    }

    .select__dropdown-indicator {
      padding: 0px;
      svg {
        width: 15px;
        height: 7.5px;
      }
    }
  }
  .select__control--is-focused {
    border: 1px solid #ffc629;
  }
  .select__control--menu-is-open {
    .select__indicators {
      .select__dropdown-indicator {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  .select__menu {
    margin: 0px;
    border-radius: 0px;
    padding: 0px;
    z-index: 999;
  }
  .select__menu-list {
    padding: 0px;
  }
  .select__indicators {
    height: 100%;
  }
  // Select Menu
  .select__menu,
  .react-select__menu {
    .select__menu-list,
    .react-select__menu-list {
      .select__option,
      .react-select__option {
        cursor: pointer;
        font-size: 15px;
        line-height: 19px;
        color: $color-black;
        font-family: $regular;
        padding: 10px 15px 9px 15px;

        &.select__option--is-focused {
          background-color: $color-bg;
          color: $color-black;
        }

        &.select__option--is-selected {
          background-color: $color-white;
          color: $color-blue-dark;
        }
      }
    }

    .select__menu-list,
    .react-select__menu-list {
      .select__group {
        .select__group-heading {
          margin-bottom: 0.5rem;
          color: green;
          font-weight: bolder;
          font-size: inherit;
        }
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-group {
  .form-control {
    min-height: 46px;
    border-color: $color-gray;

    border-width: 0.5px;

    padding-left: 15px;
    &:focus {
      border-width: 2px;
    }
  }

  input {
    color: $color-black;
    // padding-left: 17px;
  }
}
.form-control {
  border-radius: 3px;
}
input[type='radio'],
input[type='checkbox'],
// input[type='date'],
// input[type='time'],
select {
  cursor: pointer;
}
input[type="radio"]:disabled,
input[type="checkbox"]:disabled,
input[type="date"]:disabled,
input[type="time"]:disabled,
select:disabled {
  cursor: default;
}
input::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

input[type="date"],
input[type="time"],
select {
  cursor: text;
}
.notification-container {
  left: 0;
  margin: 0 auto;
  width: 450px !important;
}
.input-group {
  .input-group-prepend {
    .input-group-text {
      background: transparent;
      border-right: 0;
      border-color: $color-gray;
      color: $color-black;
    }
  }

  .noBorder {
    border-left: 0;
  }
}

.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
::placeholder {
  color: $color-gray-light !important;
}

.btn.focus,
.btn:focus,
.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.form-check-input:focus ~ .form-check-label::before {
  box-shadow: none;
}

.char_limit {
  font-size: 12px;
  font-weight: 500;
  text-align: right;
  margin-top: 5px;
  @media (max-width: 599px) {
    margin-top: 0;
  }
}

.fs-20 {
  font-size: 20px;
}

.fs-47 {
  font-size: 47px;
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}

.fs-25 {
  font-size: 25px;
}

.fs-18 {
  font-size: 18px;
}

.fs-36 {
  font-size: 36px;
}
.fs-50 {
  font-size: 50px;
}
.fs-60 {
  font-size: 60px;
}
.fs-63 {
  font-size: 63px;
}

.h30 {
  height: 30px;
}

.h25 {
  height: 25px;
}

.fs-70 {
  font-size: 70px;
}

.lh15 {
  line-height: 15px;
}

.h-45 {
  height: 45px !important;
}

.max-h-45 {
  max-height: 45px;
}

.fs-30 {
  font-size: 30px;
}

.ps-40 {
  padding-left: 65px !important;
}

.borderBottom {
  border-bottom: 1px solid $color-gray;
}

.border-top {
  border-top: 1px solid $color-gray !important;
}

.dottedBox {
  border: 4px dotted $color-yellow;
  background-color: $color-light-yellow;
  border-radius: 3px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-yellow;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.aColor {
  color: $color-blue-dark;
}

.colorYellow {
  color: $color-yellow;
}

.filter-dropdown {
  span {
    color: $color-black;
  }
}

.bb-color {
  border-bottom-color: $color-gray;
}

.red-color {
  color: $color-red;
}
.green-color {
  color: $color-success;
}
.h-60 {
  height: 60px !important;
}

.fs-15 {
  font-size: 15px;
}

.mb13 {
  margin-bottom: 13px;
}

.lh-24 {
  line-height: 24px;
}

.font-Segoe_UI {
  font-family: "Segoe UI";
}

hr {
  border-color: $color-gray;
}

.form-group select {
  border-color: $color-gray !important;
  color: $color-black;

  &:focus {
    border-color: $color-yellow !important;
    box-shadow: none;
  }
}

textarea.form-control {
  min-height: 130px;
  height: auto;
  @media (max-width: 767px) {
    height: 130px;
  }
}

.leave-info-text {
  color: $color-gray;
  font-size: 11px;
}

.bigButton {
  height: 60px;
  font-size: 20px;
  font-weight: 500;
}

.minWidth-100 {
  min-width: 100px;
}

.border-bottom {
  border-bottom: 1px solid $color-gray !important;
}

#exampleSelect {
  color: #000000;
}
.team-select {
  width: 183px;
  .select__option--is-focused {
    border-color: #ffc629 !important;
  }
}

.event-dashboard {
  .col-sm-5 {
    &:last-child {
      .draft_widget {
        margin-right: 7px;
      }
    }
  }
  .launchWallBlock {
    @media (max-width: 991px) {
      display: none;
    }
  }
  .launchWallBlockMobile {
    display: none;
    @media (max-width: 991px) {
      display: block;
      z-index: 0;
      margin-top: -60px;
      margin-bottom: 20px;
      img {
        width: 40px;
      }
    }
  }
}

.closeIcon {
  width: 12px;
  height: 12px;
}

.input-group {
  &:hover,
  &:focus,
  &:active {
    .input-group-text {
      border-color: #ffc629;
    }
  }
}

.alert {
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.2);
  color: #fff;

  img {
    margin-right: 10px;
  }

  &.alert-success {
    @include notificationColor($color-success, $color-success);
  }

  &.alert-danger {
    @include notificationColor($color-danger, $color-danger);
  }

  &.alert-warning {
    @include notificationColor($color-warning, $color-warning);
  }
}

.focus_field {
  .text-danger {
    color: $color-error !important;
  }

  .errorColor {
    border-color: $color-error !important;

    &:focus {
      border-color: $color-error !important;

      + .label-helper {
        color: $color-error !important;
      }
    }
  }
}

.loader_wrapper {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.1);

  .spinner-grow {
    width: 3rem;
    height: 3rem;
    z-index: 1032;
  }
}

.smallCircle {
  @include imgCircle(50, 50, 0, 2);

  &.nav_header {
    @include imgCircle(37, 37, 50%, 2);
  }
}

.mediumCircle {
  @include imgCircle(150, 150, 0, 2);
  @include mediaq(576px) {
    width: 78px;
    height: 78px;
  }
}

.defaultNoImageBackground {
  background-color: $color-light-yellow;
  border: 2px dashed $color-yellow;
  min-width: 162px;
  height: 100%;
}

.selectBorder {
  border: 1px solid $color-gray;
  border-radius: 3px;
  width: 100%;
  height: 45px;
  padding-bottom: 0;

  button {
    width: 94%;
    text-align: left;
  }
  .css-1uccc91-singleValue {
    position: relative;
    top: 63%;
    @media (max-width: 599px) {
      position: absolute;
      top: 50%;
    }
  }
  .css-1mlgm3b-placeholder {
    @media (max-width: 599px) {
      top: 50%;
    }
  }
  .css-e7pd8t-DropdownIndicator {
    padding-bottom: 20px;
  }
  .flag-select__option__label {
    top: 5px;
  }

  .flag-select__options {
    width: 100%;
  }
}

.br-50 {
  border-radius: 50%;
}

.currencySelect {
  // z-index: 10;
  z-index: 99;
}

.ms-30 {
  margin-left: 30px;
}

.inviteFilter {
  .react-multi-email {
    border: 1px solid $color-gray;
    border-radius: 3px !important;
    font-size: 16px;
    padding: 0 0.75rem;
    flex: 1 0;
    .data-labels {
      & > div {
        border-radius: 25px;
        background: $color-yellow;
        color: $color-black;
        padding: 8px;
        word-break: break-word;
        span {
          background-color: $color-black;
          color: $color-yellow;
          width: 14px;
          height: 14px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }
      }
    }
  }

  input {
    height: 45px;
  }
}

.deleteICon {
  position: absolute;
  top: 4px;
  right: 0;
}

.error {
  color: $color-red;
}
// Pagination design
.page-link {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  margin-top: 5px !important;
  justify-content: center;
  background: transparent;
  color: #000000;
  border: none;
  &:hover {
    background: transparent;
  }
}
.page-item.active {
  .page-link {
    z-index: 3;
    color: black;
    margin-top: 5px !important;
    background-color: #e0e0e0;
    border-color: #e0e0e0;
  }
}
._3vt7_Mh4hRCFbp__dFqBCI li {
  ._3fD5brWjGxPOXWAlZj16mH {
    vertical-align: middle !important;
  }
}
.chip {
  background: $color-yellow !important;
}
.highlightOption {
  display: flex;
  background: $color-white !important;
  color: $color-black !important;

  .checkbox {
    width: 15px;
    height: 15px;
  }
}
.optionListContainer {
  z-index: 999 !important;
  .option {
    .checkbox {
      width: 15px;
      height: 15px;
    }
  }
}
.multiSelectContainer {
  input {
    padding-left: 5px;
  }
}
._2iA8p44d0WZ-WqRBGcAuEV {
  &:focus {
    border: 1px solid #ffc629 !important;
  }
  &:active {
    border: 1px solid #ffc629 !important;
  }
  &:target {
    border: 1px solid #ffc629 !important;
  }
}
._3vt7_Mh4hRCFbp__dFqBCI li {
  background: #fff;
  color: #000000;
  &:hover {
    background: none !important;
    color: #000000 !important;
  }
  input[type="checkbox"] {
    position: relative;
    -webkit-appearance: initial;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
    left: -2%;
  }
}
// input[type="checkbox"] {
//   border: none;
//   // position: absolute;
//   // top: 22%;
//   display: block;
//   text-align: center;
//   border-radius: 0px !important;
//   content: url("../src/resources/images/Unchecked.png");
//   @include mediaq(575px) {
//     position: relative;
//   }
// }

// input[type="checkbox"]:checked {
//   border-radius: 4px !important;
//   content: url("../src/resources/images/Checkbox.png");
// }

.react-multi-email > span[data-placeholder] {
  color: $color-gray-light !important;
}
.Toastify__toast-container {
  width: auto !important;
}
.Toastify__toast {
  box-shadow: none !important;
  min-width: 500px !important;
}
.Toastify__toast--success {
  background: $color-success !important;
}
.Toastify__toast--error {
  background: $color-danger !important;
}
.text-omission {
  // white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;
}
.verison-modal-wrap {
  max-width: 402px;
  border-radius: 8px;
  h3 {
    color: #0080ff;
  }
  p {
    font-size: 16px;
  }
  @media (max-width: 599px) {
    max-width: 288px;
    margin: 0 auto;
    br {
      display: none;
    }
    svg {
      max-width: 200px;
      margin: 0 auto;
    }
    h3 {
      font-size: 18px;
    }
  }
}

@media (max-width: 599px) {
  .hide-xs {
    display: none !important;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .hide-sm {
    display: none !important;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .hide-md {
    display: none !important;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .hide-lg {
    display: none !important;
  }
}

@media (min-width: 1920px) {
  .hide-xl {
    display: none !important;
  }
}
.carousel {
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    width: 15px;
    height: 15px;
  }
}
.carousel-indicators {
  button {
    width: 10px !important;
    height: 10px !important;
    border-radius: 50% !important;
    border: 1px solid $color-yellow !important;
    opacity: 1 !important;
    background-clip: border-box !important;
  }
}
.nav-link:hover {
  color: inherit;
}

.btn-link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.card-list-section {
  .pay-card-btn {
    font-size: 16px;
    cursor: auto;
    &:hover {
      text-decoration: none;
    }
  }
}
.custom-select {
  width: auto;
  cursor: pointer;
  option {
    cursor: pointer;
  }
}
.isDisabledFlagBg {
  background-color: #e9ecef;
}

.isDisabledTagBg {
  background-color: #e9ecef !important;
  input {
    background-color: #e9ecef !important;
  }
}
