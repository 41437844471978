@import "src/resources/styles/mixins.scss";

.allOrganizationCard {
  .card {
    border-bottom: 0;
    border-radius: 0;

    .org-img {
      width: 162px;
      height: 162px;
      border-radius: 0;
      @media (max-width: 767px) {
        display: block;
        margin: 0 auto;
      }
    }

    .noImage {
      @include imgCircle(162, 162, 0, 4);
      @media (max-width: 767px) {
        display: block;
        margin: 0 auto;
      }
    }
  }

  &:last-child {
    padding-bottom: 30px;
    .card {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  .signBtn {
    width: 100%;
    @include fontSize(20, 26, 500);
    height: 45px;
    margin-left: auto;
  }

  .card_content {
    .title {
      @include fontSize(20, 24, 500);
    }

    .subTitle {
      @include fontSize(18, 24, 500);
    }

    .normalText {
      @include fontSize(18, 24, 400);
    }
    @media (max-width: 767px) {
      text-align: center;
    }
  }
}

.eventDetailsTab {
  width: 100%;
  .event-tab {
    li {
      margin-top: 10px;
      margin-bottom: 0;
      padding-bottom: 10px;
      margin-right: 10px;
      @media (max-width: 767px) {
        text-align: center;
      }
    }
  }
}
.btn-blue.btn-secondary {
  background: #4d77b3 !important;
  border: #4d77b3 !important;
  color: white !important;
  &:hover,
  &:focus {
    background: #4d77b3 !important;
    border: #4d77b3 !important;
    color: white !important;
  }
}
.invitation-modal {
  .title {
    font-size: 20px;
    font-weight: 500;
  }
}
.organization-profile-event {
  .event-tab {
    margin-bottom: 12px;
  }
  .nav-item {
    .nav-link {
      cursor: pointer;
      color: $color-black;
      &:hover {
        border: 1px solid transparent;
      }
    }
  }
  .active {
    .nav-link {
      color: $color-yellow;
    }
  }
}
.organization-profile-main {
  img {
    width: 17px !important;
    height: 17px !important;
  }
  .imgWidth {
    width: 120px !important;
    height: 120px !important;
  }
  button {
    max-width: 160px !important;
    @media (max-width: 375px) {
      max-width: 140px !important;
    }
  }
  .org-msg-block {
    width: 30% !important;
    margin: 0 auto !important;
  }
}
.search {
  input {
    font-size: 20px;
  }
}
