@import 'src/resources/styles/variables.scss';
.transfer-ownership {
  .search-list {
    @media (max-width: 639px) {
      display: flex;
      justify-content: space-between;
    }
  }
  .transfer-button {
    max-width: 112px;
    width: 100%;
    height: 46px;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    border-radius: 8px;
    @media (max-width: 639px) {
      font-size: 12px;
      line-height: 16px;
      height: 30px;
    }
  }
  .search-field-label {
    color: $color-black;
    font-size: 23.4px;
    font-weight: 500;
    line-height: 32.75px;
  }
  .search-icon {
    position: absolute;
    background: transparent;
    color: $color-yellow;
    top: 14px;
    z-index: 6;
    left: 15px;
  }
  .search-field {
    padding-left: 40px;
    border-radius: 8px !important;
    border: 1px solid #e0e0e0 !important;
  }
  .search-clear-icon {
    position: absolute;
    background: $color-gray;
    color: $color-white;
    top: 15px;
    z-index: 6;
    right: 22px;
    border-radius: 20px !important;
    padding: 5px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  .search-btn {
    height: 46px;
    font-size: 18px;
    border-radius: 8px;
  }
  .user_image {
    img {
      border-radius: 100%;
    }
  }
}
