@import "src/resources/styles/variables.scss";

.event-details-donation-feature {
  margin-top: 45px;
  .feature-donation-title {
    font-size: 23.4px;
    line-height: 29px;
    font-weight: 500;
    margin-bottom: 18px;
  }
  .donation-add-amount-card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 26px;
    @media (max-width: 999px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 599px) {
      grid-template-columns: 1fr;
    }
    .donation-amount-card {
      background: $color-white;
      padding: 27px;
      border-radius: 18px;
      border: 1px solid $gray-300;
      @media (max-width: 1400px) {
        padding: 18px;
      }
      .donation-amount-title {
        font-size: 18px;
        line-height: 23px;
        font-weight: 500;
        margin-bottom: 18px;
      }
      .donation-amount-value {
        margin-bottom: 27px;
        @media (max-width: 768px) {
          margin-bottom: 18px;
        }
        span {
          font-size: 30.42px;
          line-height: 38px;
        }
      }
      .donation-card-amount-update-btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 18px;
        // flex-wrap: wrap;
        @media (max-width: 1300px) {
          flex-direction: column;
          align-items: normal;
        }
        .donation-update-count-btn {
          min-width: 49px;
          height: 49px;
        }
        .donation-count-input-field {
          max-width: 67px;
          margin: 0px 9px;
          @media (max-width: 1300px) {
            max-width: 100%;
            width: 100%;
          }
          .donation-count-field {
            .MuiInputBase-input {
              text-align: center;
            }
            .MuiOutlinedInput-notchedOutline {
              top: -10px;
            }
          }
        }
        .donation-add-to-cart-btn {
          padding: 13px 26px;
          min-width: 148px;
          width: 100%;
        }
      }
    }
  }
  .other-donation-add-amount-card-container {
    margin-top: 27px;
    background: $color-white;
    border: 1px solid $gray-300;
    border-radius: 18px;
    padding: 27px;
    @media (max-width: 768px) {
      padding: 18px;
    }
    .other-donation-amount-title {
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
    }
    .other-donation-count-field {
      .MuiInputBase-input {
        padding: 13px 12px 12px 12px;
        font-size: 16px;
        line-height: 20px;
      }
    }
    .other-donation-add-to-cart-btn {
      padding: 13px 26px;
    }
  }
}
