@import 'src/resources/styles/variables.scss';
@import 'src/resources/styles/mixins.scss';

.invoice-table {
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #707070;
    border-radius: 10px;
    width: 500px;
  }

  .table-head {
    .MuiTableCell-root {
      padding: 14px 20px 15px 20px;
      font-family: $regular;
      font-size: 16px;
      line-height: 21px;
      font-weight: 600;
      color: $color-black;
    }
  }

  .table-row {
    .MuiTableCell-root {
      padding: 14px 20px 15px 20px;
      font-family: $regular;
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      color: $color-black;
    }

    .download-cell {
      padding: 9px 0 9px;

      .download-invoice-button {
        background-color: $color-yellow;
        border: 0;
        border-radius: 8px;
        padding: 7px 14px;
        font-family: $regular;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        color: $color-black;
      }
    }
  }
}
