@import "src/resources/styles/variables.scss";
@import "src/resources/styles/mixins.scss";

.search {
  .search-custom-dropdown {
    .search-custom-dropdown-btn {
      min-width: 120px;
      color: $color-white;
      width: 100%;
    }
  }
}

.winLossModal {
  max-width: 940px;
  .carousel {
    .carousel-inner {
      background-color: $color-gray-very-light;
      // min-height: 66vh;
      text-align: center;
      display: flex;
      align-items: center;
      border-radius: 5px;
      height: 100%;
      img {
        height: 548px;
        :hover {
          background-color: transparent;
          // height: auto !important;
        }
      }
    }

    .carousel-indicators {
      display: none;
    }

    .carousel-control-next,
    .carousel-control-prev {
      filter: invert(100%);
    }
  }

  .circle {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 4px dotted $color-yellow;
  }

  .square {
    height: 60px;
    width: 60px;
    border: 4px dotted $color-yellow;
  }

  .winningText {
    color: $color-blue-sm-dark;
  }

  .box {
    border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
    padding: 20px 0;
    margin: 20px 0;
    text-align: center;
    justify-content: space-around;

    & > div {
      border-right: 1px solid $color-gray;
      min-width: calc(100% / 3);

      &:last-child {
        border-right: 0;
      }
    }
  }

  .bottomLine {
    border-bottom: 1px solid $color-gray;
    padding-bottom: 30px;
  }

  .arrowReverse {
    &.down {
      transform: rotate(180deg);
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.winLoss {
  .search {
    .search-btn {
      max-width: 120px;
      width: 100%;
    }
  }
  .event-tab {
    li {
      min-width: 70px;
      text-align: center;

      &.active {
        font-weight: 500;
      }
    }
  }

  .wonItemPrizeCard {
    .card {
      border: 1px solid #dddddd;
      margin-bottom: 30px;
    }
    .lot-image {
      height: 232px !important;
    }
  }
  @include mediaq(991px) {
    padding-left: 0px !important;
  }
  .page-title-block {
    @include mediaq(991px) {
      margin-bottom: 0px !important;
    }
  }
  .page-title {
    @include mediaq(991px) {
      margin-top: -50px;
      z-index: 0;
      margin-bottom: 29px !important;
      line-height: 33px;
      font-size: 30px;
      text-align: center;
    }
    @include mediaq(599px) {
      font-size: 25px;
    }
  }
}
.imageWrapper {
  height: 200px;
  img {
    height: 100%;
  }
}
.imageOverlay {
  box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.45);
}
.image-overlay-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    height: auto;
    max-width: 85px;
  }
}
.imgWrapper {
  height: 200px;
  background: #f3f2ed;
  background-size: cover !important;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
