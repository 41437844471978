@import "src/resources/styles/variables.scss";
@import "src/resources/styles/mixins.scss";

.event-common-card {
  position: relative;
  height: 100%;
  border-radius: 5px;
  box-shadow: -1px 1px 4px rgba(0, 0, 0, 0.16);
  background: $color-white;
  .card-badge {
    position: absolute;
    top: 0;
    right: 30px;
  }
  .event-card-image-container {
    height: 150px;
    border-radius: 5px 5px 0px 0px;
    background-color: $color-gray-very-light;
    .event-card-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 5px 5px 0px 0px;
    }
  }
  .event-card-content {
    padding: 18.4px 15px 19px 15px;
  }
  .event-type {
    background-color: $color-yellow;
    padding: 3px 9px 3px 9px;
    border-radius: 20px;
    font-size: 13.84px;
    line-height: 18px;
    display: inline-block;
  }
  .event-date {
    color: $color-blue-dark;
    font-size: 13.84px;
    line-height: 18px;
    margin-top: 9px;
    margin-bottom: 3px;

    .event-time {
      font-size: 13.84px;
      line-height: 18px;
    }
  }
  .event-name {
    font-size: 18px;
    line-height: 23px;
    font-weight: 500;
    margin-bottom: 9px;
  }
  .eventname-button {
    background: transparent;
    border: none;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px;
    font-size: 20px;
    font-weight: 500;
  }
  .time-left {
    margin-bottom: 3px;
  }
  .time-left,
  .amount-raised {
    span {
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
    }
  }
  .extra-info {
    margin-top: 9px;

    img {
      margin-right: 9px;
    }
  }
}
