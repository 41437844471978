@import "./variables.scss";
@import "./mixins.scss";

::-webkit-input-placeholder {
  /* Edge */
  color: #818181;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #818181;
}

::placeholder {
  color: #818181;
}

input,
textarea,
select {
  border-color: $color-gray !important;
}

input,
textarea,
input:focus,
textarea:focus {
  color: $color-black;
}

.question-icon {
  height: 20px;
  width: 20px;
  margin-top: 2px;
  // padding-top: 2px;
  background-color: $color-light-black;
  border-radius: 50%;
  color: $color-white;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  @include mediaq(767px) {
    // font-size: 13px;
  }
}
body.crash {
  overflow: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}
.text-yellow {
  color: $color-yellow;
}

.gray-bg {
  background-color: $color-bg;
}

.white-bg {
  background-color: $bg-white;
}

.container-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-content {
  min-height: calc(100vh - 132px);
}
.bg-gray {
  background: $color-bg;
}
.app-button {
  border-radius: 3px;
  font-weight: 500;
  // height: 60px;
  padding: 8px 15px;
  // min-width: 190px;
  width: 33%;
  @include mediaq(767px) {
    padding: inherit;
  }
  @include mediaq(480px) {
    @include fontSize(14, 16, 500);
    padding: 15px 5px;
  }
}

.main-button,
.main-button.btn-secondary {
  background-color: $color-yellow;
  border-color: $color-yellow;
  color: $color-black;
  border-radius: 3px;
  width: 100%;
  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
    background-color: $color-yellow !important;
    border-color: $color-yellow !important;
    color: $color-black !important;
  }
}

.singedin-button {
  background-color: $color-blue-dark;
  border-color: $color-blue-dark;
  color: $color-white;
  border-radius: 3px;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
    background-color: $color-blue-dark !important;
    border-color: $color-blue-dark !important;
    color: $color-white !important;
  }
}

.invite-button {
  background-color: $color-red;
  border-color: $color-red;
  color: $color-white;
  border-radius: 3px;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
    background-color: $color-red !important;
    border-color: $color-red !important;
    color: $color-white !important;
  }
}

.decline-button {
  background-color: $color-error;
  border-color: $color-error;
  color: $color-white;
  border-radius: 3px;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
    background-color: $color-error !important;
    border-color: $color-error !important;
    color: $color-white !important;
  }
}

.black-button {
  background-color: $color-light-black;
  border-color: $color-light-black;
  color: $color-white;
  border-radius: 3px;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
    background-color: $color-light-black;
    border-color: $color-light-black;
    color: $color-white;
  }
}

.blue-button {
  background-color: $color-blue-dark;
  border-color: $color-blue-dark;
  color: $color-white;
  border-radius: 3px;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;
  }
}

.red-button {
  background-color: $color-red;
  border-color: $color-red;
  color: $color-white;
  border-radius: 3px;

  &:hover,
  &:focus,
  &:active {
    opacity: 0.8;
    background-color: $color-red;
    border-color: $color-red;
    color: $color-white;
  }
}

.dark-blue-button {
  background-color: $color-blue-dark;
  border-color: $color-blue-dark;
  color: $color-white;
}

.full-height {
  min-height: calc(100vh - 132px);
}

.hollow-button {
  background: transparent;
  border: none;
  padding: 0;
  color: $color-black;
}

.event-tab {
  display: flex;
  list-style-type: none;

  li {
    font-size: 1.25rem;
    margin: 30px 30px 30px 0;
    @include fontSize(20, 24, 400);
    @include mediaq(991px) {
      font-size: 16px;
      margin: 20px 20px 20px 0;
    }
    @include mediaq(767px) {
      margin: 20px 15px 20px 0;
      font-size: 15px;
    }
    @include mediaq(576px) {
      @include fontSize(16, 20, 400);
    }
  }

  .active {
    color: $color-yellow;
    border-bottom: solid 5px $color-yellow;
    padding-bottom: 15px;
    @media (max-width: 599px) {
      padding-bottom: 0;
    }
  }
}
.max-width200 {
  max-width: 200px;
  margin-left: auto;
}
.error-bg-img {
  object-fit: scale-down !important;
  border: 4px dashed $color-yellow;
  background: $color-light-yellow;
  padding: 0px 10px;
}

body {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .btn-primary {
    color: $color-black;
    background-color: $color-yellow;
    font-family: $regular;
    text-transform: inherit;
    padding: 13px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 400;
    letter-spacing: 0;
    border-radius: 8px;
    &:hover {
      color: $color-black;
      background-color: $color-yellow;
      opacity: 0.8;
    }
    &.Mui-disabled {
      cursor: not-allowed;
      color: $color-black;
      opacity: 0.5;
    }
  }
  .b-btn-primary {
    color: $color-black;
    background-color: $color-white;
    outline: 2px solid $color-yellow;
    font-family: $regular;
    text-transform: inherit;
    padding: 13px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 400;
    letter-spacing: 0;
    border-radius: 8px;
    &:hover {
      color: $color-black;
      background-color: $color-white;
      opacity: 0.8;
    }
    &:focus {
      outline: 2px solid $color-yellow;
    }
    &.Mui-disabled {
      cursor: not-allowed;
      color: $color-black;
      opacity: 0.5;
    }
  }
  .btn-blue {
    color: $color-white;
    background-color: $color-blue-dark;
    font-family: $regular;
    text-transform: inherit;
    padding: 13px;
    font-size: 18px;
    line-height: 23px;
    font-weight: 400;
    letter-spacing: 0;
    border-radius: 8px;
    &:hover {
      color: $color-white;
      background-color: $color-blue-dark;
      opacity: 0.8;
    }
    &.Mui-disabled {
      cursor: not-allowed;
      color: $color-white;
      opacity: 0.5;
    }
  }
  .custom-textfield {
    .MuiFormLabel-root,
    .MuiOutlinedInput-root,
    .MuiFormHelperText-root {
      font-family: $regular;
    }
  }
  .error-text {
    font-family: $regular;
    color: $color-error !important;
  }
}
.event-visibility-text {
  font-size: 13.84px !important;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 20px;
  span {
    font-size: 13.84px;
    line-height: 20px;
    font-weight: 500;
  }
}

.m18 {
  margin: 18px;
}
.mb18 {
  margin-bottom: 18px;
}
.mt18 {
  margin-top: 18px;
}
.ml18 {
  margin-left: 18px;
}
.mr18 {
  margin-right: 18px;
}
.no-data-found {
  font-size: 18px;
  line-height: 23px;
  padding: 10px;
  text-align: center;
}

.modal-close-icon {
  background: $gray-300;
  padding: 9px;
  border-radius: 8px;
  @media (max-width: 959px) {
    padding: 6px 6px 5px 5px;
    height: 27px;
    width: 27px;
  }
}

body {
  .logout-modal-container {
    max-width: 613px;
    .modal-content {
      padding: 0px;
      @media (max-width: 768px) {
        padding: 0px !important;
      }
      .modal-body {
        padding: 0px;
        @media (max-width: 768px) {
          padding: 0px;
        }
        .logout-modal-details-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          padding: 27px 27px 40px 27px;
          @media (max-width: 768px) {
            padding: 12px 12px 36px 27px;
          }
          .logout-modal-img {
            width: 105.31px;
            height: 108px;
            margin-right: 27.7px;
            @media (max-width: 768px) {
              margin-right: 0;
              margin-bottom: 27px;
            }
            img {
              width: 105.31px;
              height: 108px;
            }
          }
          .logout-modal-details-text-wrap {
            margin-right: 30px;
            text-align: left;
            display: flex;
            justify-content: left;
            align-items: center;
            @media (max-width: 768px) {
              flex-direction: column;
              align-items: flex-start;
            }
            .logout-modal-details-text-title {
              font-size: 30.42px;
              line-height: 38px;
              font-weight: 500;
              margin-bottom: 18px;
              @media (max-width: 768px) {
                font-size: 23.4px;
                line-height: 29px;
                margin-bottom: 16px;
              }
            }
            .logout-modal-details-text-description {
              font-size: 18px;
              line-height: 23px;
              font-weight: 400;
            }
          }
        }
        .logout-modal-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 13px;
          border-top: 1px solid $gray-300;
          padding: 27px;
          @media (max-width: 768px) {
            flex-direction: column;
            gap: 9px;
          }
        }
      }
    }
  }
  .custom-checkbox-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .MuiFormControlLabel-root {
      margin: 0px;
    }
    .custom-checkbox-label {
      margin-left: 13px;
    }
  }
}
