@import "src/resources/styles/variables.scss";
@import "src/resources/styles/mixins.scss";

.raffleTab {
  ul {
    margin-bottom: 1em;
    @media (max-width: 1023) {
      display: flex;
      justify-content: space-around;
      width: 100% !important;
    }

    li {
      background-color: $color-bg;
      border: 1px solid #dddddd;
      margin-right: 6px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      min-width: 150px;
      text-align: center;
      cursor: pointer;

      @media (max-width: 1023) {
        min-width: unset;
        width: 32%;
        margin-right: 0px;
      }

      &.active {
        background-color: $color-white;
        color: $color-black;
        border-bottom: 0;

        a {
          border-top: 5px solid $color-yellow !important;
          border-radius: 0;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border: 0;
        }
      }

      a {
        font-size: 1rem;
        border: 0;
        border-top: 5px solid transparent !important;
        color: $color-black;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  .tab-content {
    .active {
      background-color: $color-white;
      color: $color-black;
    }
  }

  .ticketBundleTab {
    img {
      transition: all 0.5s ease-in-out;

      &.down {
        transform: rotate(180deg);
        transition: all 0.5s ease-in-out;
      }
    }
  }
  .bundlePrice {
    padding-left: 60px;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  .pricesForm {
    .uploader.small {
      width: 100%;
    }
  }

  .wonItemPrizeCard {
    .card {
      padding: 10px;
      box-shadow: 1px 1px 2px 1px #ddd;
      border: 0;

      .card-body {
        padding: 0;

        .card-title {
          font-size: 18px;
          font-weight: 500;
        }

        .card-subtitle {
          &.subTitleText {
            color: $color-blue-dark;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
.raffleTabMobile {
  ul li {
    @media (max-width: 767px) {
      min-width: 109px !important;
    }
    @media (max-width: 374px) {
      min-width: 80px !important;
    }
  }
}
.add-price-modal {
  .modal-content {
    padding: 20px;
  }

  .modalHeader {
    padding: 15px 20px;
  }

  .addPriceModalForm {
    .uploader.small {
      width: 100%;
    }
    .message p {
      font-size: 12px;
      &:first-child {
        font-weight: 500;
      }
    }
  }

  .mWidth-100 {
    min-width: 100%;
  }
}

.draft_widget {
  max-width: 150px;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  padding: 5px;
  padding-left: 15px;
  box-shadow: 1px 1px 1px 1px #ddd;
  font-size: 12px;
  position: relative;
  font-weight: 500;
  margin-left: auto;

  img {
    position: absolute;
    right: -8px;
    top: -8px;
    height: 45px;
    width: 45px;
  }
}
.draw_winner_modal {
  .smallFont {
    font-size: 12px;
  }

  .draw_table {
    th {
      font-weight: 500;
      vertical-align: middle;
      background: #f9f9f9;
      border: 0;
      padding: 0.4rem 0.75rem;
    }

    tbody {
      td {
        padding: 0.75rem;
      }
      tr {
        &:first-child {
          td {
            border-top: 0;
          }
        }
      }
    }

    .draw-btn {
      height: 30px;
      text-transform: capitalize;
      min-width: 120px;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.raffleWall {
  background-color: $color-light-yellow;

  .left-side {
    box-shadow: 3px 0 1px #ddd;
    padding-top: 40px;
    min-height: 100vh;
    background-color: $color-white;
    width: 30%;

    .box {
      margin-left: 30px;
      margin-right: 50px;
      border: dashed 4px $color-yellow;
      background: $color-light-yellow 0% 0% no-repeat padding-box;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-yellow;
      font-size: 18px;
      font-weight: 500;
      min-height: 198px;
      margin-bottom: 40px;
      border-radius: 5px;
    }
  }

  .raffleWallCard {
    margin-top: 40px;

    .row {
      padding: 20px;
      box-shadow: 1px 1px 6px 2px #ddd;
      border-radius: 5px;
      background-color: $color-white;
    }

    .card-img {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .card-content {
      margin-left: 20px;
    }
  }

  .ticketSold {
    background-color: $color-light-black;
    color: $color-white;
    font-size: 35px;
    padding: 20px 15px;
    margin: 20px 0 0;
    border-radius: 5px;
  }

  .congratulationOverlay {
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;

    animation: fadeIn 5s;
    -webkit-animation: fadeIn 5s;
    -moz-animation: fadeIn 5s;
    -o-animation: fadeIn 5s;
    -ms-animation: fadeIn 5s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    img {
      width: 100%;
    }
  }
}

.raffleDraw {
  gap: 10px;
}
.raffleDrawBlockMoblie {
  display: none;
  @include mediaq(991px) {
    display: block;
    z-index: 0;
    margin-top: -60px;
    margin-bottom: 20px;
    img {
      width: 40px;
    }
  }
}
.raffleDrawBlock {
  .print_sold_ticket {
    max-width: 167px;
  }
  @include mediaq(991px) {
    display: none;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.raffleFetures-button-view {
  z-index: 999;
  position: sticky;
  bottom: 0;
  background: $color-light-yellow;
  padding: 8px;
  margin-bottom: 60px;
  box-shadow: 0px 0px 6px #00000040;
  margin-top: 40px;
  .app-button {
    border-radius: 3px;
    font-weight: 500;
    padding: 8px 15px;
    width: 33%;
  }
}
.raffleTicketBundleTab {
  .raffleTicketWrapper {
    h5 {
      word-break: break-all;
      line-height: 21px;
      margin-bottom: 7px;
    }
    .col-sm-2 {
      padding-left: 0;
    }
  }
}
@media (min-width: 576px) and (max-width: 1600px) {
  .raffleprizeTab {
    .addrafflebutton {
      width: 100%;
    }
    .col-sm-6 {
      &:nth-child(even) {
        padding-left: 0px !important;
      }
    }
  }
}
@include mediaq(575px) {
  .raffleprizeTab {
    .catalogueBtn {
      min-width: 156px !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      height: 30px !important;
      padding: 0 !important;
      margin: 15px 0 5px !important ;
    }
    .mt-3 {
      &:first-child {
        margin-top: 0 !important;
      }
    }
    .rafflePrizeCard {
      .wonItemPrizeCard {
        .card {
          .card-body {
            min-height: auto;
            .card-title {
              font-size: 16px;
              margin-top: 15px !important;
              line-height: 24px;
              margin-bottom: 8px !important;
            }
            .card-subtitle {
              font-size: 12px;
              line-height: 16px;
              span {
                font-size: 12px;
                line-height: 16px;
              }
              &.subTitleText {
                font-size: 12px;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
    .addrafflebutton {
      width: 100% !important;
      font-size: 15px !important;
      height: 45px !important;
    }
  }
  .raffleTicketBundleTab {
    .raffleTicketbtn {
      width: 166px !important;
      height: 30px !important;
      padding: 0 !important;
      font-size: 12px !important;
      margin: 15px 0 5px !important ;
    }
    .raffleTicketWrapper {
      h5 {
        font-size: 16px;
        word-break: break-all;
        line-height: 21px;
      }
      .col-sm-10 {
        flex: 0 0 80%;
        max-width: 80%;
      }
      .col-sm-2 {
        flex: 0 0 20%;
        max-width: 20%;
        padding-left: 0;
        span {
          &:first-child {
            margin-right: 12px !important;
          }
        }
      }
    }
  }
}
