@import '../../../../resources/styles/variables.scss';
@import '../../../../resources/styles/mixins.scss';

.notice {
  background-color: $color-light-yellow;
  padding: 15px 10px;
  display: flex;
  align-items: end;
  border-radius: 5px;
  .notice_title {
    @include fontSize(16, 19, 500);
  }
  .message {
    p {
      @include fontSize(12, 18, 400);
      &:first-child {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
