.social-links {
  .event-section {
    .section-header {
      display: flex;
      align-items: center;
      border-bottom: solid 1px #707070;
      margin-bottom: 30px;

      .icon {
        background-color: #ffc629;
        height: 63px;
        width: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media (max-width: 767px) {
        margin-bottom: 23px;
        .icon {
          height: 40px;
          width: 40px;
          img {
            width: 26px;
          }
        }
      }
      h3 {
        font-weight: normal;
        margin-left: 20px;
        font-size: 25px;
        line-height: 30px;
        @media (max-width: 767px) {
          font-size: 18px;
        }
      }
    }
    .social-link-wrap {
      .focus_field {
        input {
          margin-bottom: 20px;
        }
        .form-control:focus {
          border-width: 1px;
        }
        .label-helper {
          opacity: 1;
          color: #707070;
          top: -10px;
          bottom: auto;
          font-family: Roboto;
          line-height: 1;
          padding: 4px;
          z-index: 9;
          background: #fff;
          left: 10px;
        }
      }
      input:focus + .label-helper {
        color: #ffc629;
      }
      .collapse-wrap {
        .collapse-icon {
          column-gap: 15px;
          img {
            transition: all 0.1s 0.1s;
          }
          .closed {
            transform: rotate(180deg);
            transition: all 0.1s 0.1s;
          }
        }
      }
    }
    .link-btn {
      padding: 5px 14px;
      font-size: 12px;
    }
    @media (max-width: 767px) {
    }
  }
  .personal-button-view {
    margin-top: 40px;
    z-index: 999;
    position: sticky;
    bottom: 0;
    background: #fff8e4;
    padding: 8px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 6px #00000040;
    .button-style {
      display: flex;
      justify-content: center;
    }
    .app-button {
      border-radius: 3px;
      font-weight: 500;
      padding: 10px 15px;
      width: 305px;
    }
  }
}
