.welcome-message {
  height: 90px;
  background-color: #ffc629;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .close-icon {
    cursor: pointer;
    padding-right: 20px;
  }

  p {
    text-align: center;
    font-size: 18px;
    line-height: 24px;

    width: 100%;
  }
}
.remove-msg {
  display: none;
}
