@import "src/resources/styles/variables.scss";
@import "src/resources/styles/mixins.scss";

.create-event-container {
  ::placeholder {
    color: $color-gray-light !important;
  }
  .page-title {
    line-height: 0.8;
    margin-top: 54px;
    @media (min-width: 992px) and (max-width: 1500px) {
      font-size: 40px !important;
    }
    @include mediaq(991px) {
      margin-top: 30px;
      z-index: 0;
      margin-bottom: 15px !important;
      line-height: 33px;
      font-size: 30px;
      text-align: center;
    }
    @include mediaq(599px) {
      font-size: 25px;
    }
  }
  input,
  textarea,
  input:focus,
  textarea:focus {
    color: $color-black;
  }
  .date-picker {
    border: 1px solid #707070;
    border-radius: 3px;
    height: 45px;
    position: relative;
  }
  .createEvent {
    align-items: flex-end;
    .file-uploader {
      font-size: 18px;
      font-weight: 500;

      .dropzone {
        width: 100%;
        height: 288px;
        margin-left: 0px !important;
        @media (max-width: 767px) {
          height: 150px;
        }

        p {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
    .message {
      margin-left: 0;
    }
    .imageSpecification {
      p {
        font-size: 14px;
      }
    }
    .image-display-list {
      .image-item {
        margin: 0;
        width: 100%;
        .longImg {
          width: 100%;
          margin-bottom: 0;
          height: 288px;
          object-fit: fill;
          @media (max-width: 767px) {
            height: 150px;
          }
        }
      }
    }
  }
  .event-section {
    @media (max-width: 767px) {
      h5 {
        font-size: 16px;
      }
    }
    .file-uploader-container {
      @include mediaq(767px) {
        display: none;
      }
    }
    .eventName {
      @media (max-width: 767px) {
        margin-top: 13px !important;
      }
    }
    .section-header {
      display: flex;
      align-items: center;
      border-bottom: solid 1px $color-gray;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }

      h3 {
        font-weight: normal;
        margin-left: 20px;
        @include fontSize(25, 30, 400);
        @media (min-width: 768px) and (max-width: 1500px) {
          margin-left: 15px;
          @include fontSize(20, 22, 400);
        }
        @media (max-width: 599px) {
          color: #000;
          line-height: 24px;
        }
        @include mediaq(767px) {
          @include fontSize(18, 22, 400);
          margin-left: 10px;
        }
      }

      .icon {
        background-color: $color-yellow;
        height: 63px;
        width: 63px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (min-width: 768px) and (max-width: 1500px) {
          height: 45px;
          width: 45px;
          img {
            height: 27px;
            width: 27px;
          }
        }
        @include mediaq(767px) {
          height: 40px;
          width: 40px;
          img {
            height: 21px;
            width: 21px;
          }
        }
      }

      .add-new-banking-details {
        margin-left: auto;
        background: transparent;
        border: none;
        color: $color-black;
        font-weight: 500;
        font-size: 16px;
        width: auto;
        @include mediaq(767px) {
          display: none;
        }
      }
    }
    .add-new-banking-detailsMobile {
      display: none;
      @include mediaq(767px) {
        display: block;
        margin-left: 0;
        background: transparent;
        border: none;
        color: $color-black;
        font-weight: 500;
        font-size: 16px;
        padding: 0;
        &:focus {
          box-shadow: none !important;
        }
        &:active {
          color: $color-black !important;
          background: none !important;
        }
      }
    }
    .visibility_title {
      @include fontSize(20, 24, 400);
      @include mediaq(767px) {
        @include fontSize(16, 19, 400);
      }
    }
    .app-button {
      @include mediaq(767px) {
        padding: 12px 4px;
        font-size: 15px;
        line-height: 18px;
      }
    }
    .char_limit {
      font-size: 12px;
      font-weight: 500;
      text-align: right;
      margin-top: 5px;
      @media (max-width: 599px) {
        margin-top: 0;
      }
    }
    .helpText {
      color: $color-gray !important;
      @media (max-width: 767px) {
        font-size: 11px !important;
      }
    }
    .inviteFilter .react-multi-email > div {
      @media (max-width: 767px) {
        padding: 5px !important;
      }
    }
    .react-multi-email [data-tag] {
      @media (max-width: 767px) {
        font-size: 12px !important;
      }
    }
    .react-multi-email > input {
      @media (max-width: 767px) {
        padding: 4px !important;
      }
    }
  }
  &.noOffset {
    margin-left: 0 !important;
    @include mediaq(991px) {
      padding: 0;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
}
.popover {
  @include mediaq(767px) {
    max-width: 340px !important;
    width: 100% !important;
  }
  @include mediaq(374px) {
    max-width: 300px !important;
    width: 300px !important;
  }
}
.custome-dropdown-arrow {
  select {
    background: url("../../../resources/images/DropdownDown.png") no-repeat
      right;
    -webkit-appearance: none;
    background-position-x: 98%;
    background-size: 13px;
    cursor: pointer;
  }
}
@media (max-width: 991px) {
  .bankDetails .page-title {
    margin-top: 25px !important;
  }
}
.formWrapper {
  .form-group.focus_field {
    input,
    select {
      min-height: 45px;
    }
  }
  select {
    min-height: 45px;
    padding-left: 15px;
  }
  .selectBorder {
    height: 45px;

    [class$="ValueContainer"] {
      padding: 0 15px;
      height: 100%;
    }
  }
  .bankList {
    border-bottom: 1px solid $color-gray;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}
.tag-fieldset {
  display: none;
}
.tag-fixedfield {
  position: relative;
  .tag-fieldset {
    display: inline-block;
    position: absolute;
    top: -15px;
    left: 12px;
    background: white;
  }
}
.delete-event-Modal {
  @media (max-width: 767px) {
    width: 282px;
    margin: 0.5rem auto;
  }
  .modal-content {
    padding: 15px 0 0 0;
    .text-end img {
      @media (max-width: 767px) {
        padding-right: 0px !important ;
      }
    }
  }
  .modal-body {
    padding: 0px 60px 30px;
    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 30px !important;
    padding-top: 0 !important;
    line-height: 23px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 27px;
      padding-bottom: 14px !important;
    }
  }
  .app-button {
    width: 218px !important;
    border-radius: 3px;
    font-size: 20px;
    font-weight: 500;
  }
  .mobileButton {
    @media (max-width: 767px) {
      display: block !important;
    }
    .app-button {
      @media (max-width: 767px) {
        width: 100%;
        font-size: 15px;
        height: 45px;
        margin-bottom: 15px;
        padding: 0;
      }
    }
  }
}
.cancel-change-modal {
  @media (max-width: 767px) {
    width: 282px;
    margin: 0.5rem auto;
  }
  .modal-content {
    padding: 15px;
    border-radius: 5px;
    @media (max-width: 767px) {
      padding: 0px !important;
      padding-bottom: 15px !important;
    }
  }
  .modal-body {
    padding: 0 45px 15px;
    @media (max-width: 767px) {
      padding: 0 30px;
    }
  }
  .sub-title {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 28px;
    @media (max-width: 767px) {
      padding-bottom: 15px;
      line-height: 24px;
    }
  }
  .app-button {
    width: 218px;
    padding: 18px 0px;
    border-radius: 3px;
    font-size: 15px;
  }
  .continue-button {
    font-size: 14px;
    margin-left: 15px;
    @media (max-width: 767px) {
      margin-left: 0px;
    }
  }
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    padding-bottom: 19px;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 27px;
      padding-bottom: 12px;
    }
  }
  .mobileButton {
    @media (max-width: 767px) {
      display: block !important;
    }
    .app-button {
      @media (max-width: 767px) {
        width: 100%;
        font-size: 15px;
        height: 45px;
        margin-bottom: 15px;
        padding: 0;
      }
    }
  }
}
.bankmodalMobile {
  @media (max-width: 834px) {
    width: 574px;
    margin: 0.5rem auto !important;
  }
  @media (max-width: 575px) {
    width: 282px;
    margin: 0.5rem auto !important;
  }
  .modal-body {
    @media (max-width: 1500px) {
      padding: 0;
    }
  }
  .modal-content {
    @media (max-width: 767px) {
      padding: 10px !important;
    }
  }
  .page-title {
    text-align: center !important;
    @media (max-width: 991px) {
      padding: 0 10px !important;
      margin-top: 0px !important;
    }
  }
  .bankDetails .page-title {
    @media (min-width: 992px) and (max-width: 1500px) {
      font-size: 40px !important;
    }
    @media (max-width: 991px) {
      margin-bottom: 20px !important;
      margin-top: 0px !important;
    }
  }
}
.logout-modal-mobile {
  @media (max-width: 767px) {
    width: 282px;
    margin: 0.5rem auto;
    .modal-body {
      padding: 0px !important;
    }
    h3 {
      font-size: 18px;
      line-height: 22px;
      padding-bottom: 0 !important;
    }
    .app-button {
      width: 100% !important;
      margin-right: 8px !important;
      margin: 14px 0px 10px !important;
    }
  }
}
.create-button-view {
  z-index: 999;
  position: sticky;
  bottom: 0;
  background: $color-light-yellow;
  // margin-right: -50%;
  // margin-left: -88%;
  padding: 8px;
  margin-bottom: 20px;
  margin-top: 20px;
  box-shadow: 0px 0px 6px #00000040;
  .app-button {
    border-radius: 3px;
    font-weight: 500;
    padding: 8px 15px;
    width: 33%;
  }
}
input[type="date"],
input[type="time"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}
@media (max-width: 599px) {
  .org-message-modal {
    .modal-content {
      padding: 20px !important;
    }
    h3 {
      font-size: 18px;
      line-height: 22px;
    }
    p {
      font-size: 16px;
      line-height: 22px;
    }
    .main-button {
      font-size: 15px;
      height: auto !important;
      margin-top: 16px !important;
    }
  }
}
.delete-lot-modal {
  @media (max-width: 767px) {
    width: 353px !important;
    margin: 0.5rem auto !important;
    .modal-body {
      padding: 0;
    }
    .title {
      font-size: 16px;
      line-height: 21px;
      padding-top: 14px !important;
      padding-bottom: 25px !important;
    }
    .pe-3 {
      padding-right: 0px !important;
    }
    .app-button {
      width: 158px !important;
      height: 45px !important;
      font-size: 15px !important;
      margin-bottom: 25px;
    }
  }
  @media (max-width: 374px) {
    width: 310px !important;
    margin: 0.5rem auto !important;
  }
}
.event-modal {
  max-width: 666px;
  input {
    height: auto !important;
  }
  svg.model-close {
    position: absolute;
    top: 55px;
    right: 35px;
    cursor: pointer;
  }
  .modal-body {
    padding: 0px;
  }
  .modal-content {
    padding: 36px;
    border: none;
  }
  .event-grid-wrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 18px;
    margin-top: 37px;
  }
  .event-content {
    border: 1px solid #e2e2e2;
    padding: 18px;
    border-radius: 18px;
    position: relative;
    svg {
      display: block;
      margin: 0 auto;
    }
    .event-description {
      .event-radio {
        margin-bottom: 17px;
        height: 115px;
      }
      .MuiButtonBase-root {
        padding-top: 0px;
        padding-bottom: 0px;
      }
      p:not(:last-child) {
        margin-bottom: 28px;
      }
      h4 {
        margin-top: 29px;
        margin-bottom: 9px;
      }
    }
    .event-button {
      border-radius: 5px;
      font-weight: 600;
      padding: 6.5px 12px;
      position: absolute;
      bottom: 9px;
      left: 0;
      right: 0;
      width: calc(100% - 36px);
      margin: 0 auto;
    }
    .form-check-input {
      margin-top: 3px;
    }
    .form-check-label {
      line-height: 19px;
    }
    .form-check:not(:last-child) {
      .form-check-label {
        margin-bottom: 11px;
      }
    }
  }
  @media (max-width: 767px) {
    svg.model-close {
      top: 7px;
      right: 7px;
    }
    .modal-content {
      padding: 30px 14px 92px 14px;
      h2 {
        font-size: 18px;
      }
    }
    .event-grid-wrap {
      grid-gap: 6px;
      margin-top: 10px;
    }
    .event-content {
      padding: 15px 7px;
      .event-description {
        h4 {
          margin-top: 21px;
          margin-bottom: 4px;
          font-size: 16px;
        }
        p:not(:last-child) {
          margin-bottom: 11px;
        }
        .MuiFormControlLabel-root {
          .MuiTypography-root {
            font-size: 12px;
          }
        }
        .event-radio {
          margin-bottom: 0;
          height: auto;
        }
      }
      .event-button {
        bottom: -65px;
        padding: 6.5px 1px;
        font-size: 15px;
        width: 100%;
      }
    }
  }
  @media (max-width: 599px) {
    .event-content {
      svg {
        max-width: 95px;
        max-height: 68px;
      }
    }
  }
  @media screen and (max-width: 374px) {
    .event-content {
      .event-button {
        font-size: 13px;
      }
    }
  }
}
