@import 'src/resources/styles/variables.scss';
@import 'src/resources/styles/mixins.scss';

.file-uploader {
  .dropzone {
    border: dashed 4px $color-yellow;
    background: #fff8e4 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-yellow;
    font-size: 18px;
    min-height: 180px;
    max-width: 180x;
    font-weight: 500;
    padding: 10px;
    cursor: pointer;
    @include mediaq(480px) {
      font-size: 15px;
    }
    @media (max-width: 768px) {
      padding: 0 20px;
    }

    p {
      margin-bottom: 0;
      font-size: 14px;
      text-align: center;
      @media (max-width: 479px) {
        font-size: 10px;
      }
    }
  }
}
