@import "src/resources/styles/variables.scss";
@import "src/resources/styles/mixins.scss";

.tickets {
  .add-ticket-btn {
    font-size: 12px;
    color: $color-black;
    background-color: $color-yellow;
    border-color: $color-yellow;
    min-width: 126px;
    padding: 7px 15px;
    margin-right: 0 !important;
  }

  .ticket-status-card {
    &.manage-features {
      .card {
        margin-bottom: 10px;
      }

      .sub-heading {
        font-size: 25px;
        font-weight: normal;
      }

      .card-heading {
        padding: 12px;
        background: #333;
        color: #fff;
        font-size: 16px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          transition: all 0.5s ease-in-out;

          &.down {
            transform: rotate(180deg);
            transition: all 0.5s ease-in-out;
          }
        }
      }

      .list {
        margin-bottom: 16px;
        ul {
          list-style: none;

          li {
            list-style: none;
          }
        }

        img {
          margin-right: 10px;
          max-width: 16px;
        }

        .Draft {
          color: #f36300;
          font-weight: 500;
        }

        .Active {
          color: $color-blue-dark;
        }
        .Closed {
          color: $color-dark-blue;
        }
        .Suspended {
          color: $color-red;
        }

        .font-medium {
          font-weight: bold;
        }

        span {
          font-size: 12px;
        }
      }

      .card-footer {
        padding: 14px 5px 14px 20px;
        @media (min-width: 992px) and (max-width: 1280px) {
          padding: 14px 5px 14px 5px;
        }
        a {
          color: $color-red;
          text-decoration: underline;
          font-weight: bold;
          font-size: 12px;
        }

        .status-preview-btn {
          height: 30px;
          color: $color-blue-dark;
          background-color: $color-light-blue;
          border-color: $color-blue-dark;
          margin-top: 0 !important;
          font-size: 12px;
          font-weight: 500;
          width: 72px;
          text-align: center;
          padding: 7px;
          line-height: 1.4;
        }

        .status-publish-btn {
          height: 30px;
          color: $color-white;
          background-color: $color-blue-dark;
          border-color: $color-blue-dark;
          margin-top: 0 !important;
          font-size: 12px;
          font-weight: 500;
          width: 102px;
          padding: 7px;
          line-height: 1.4;
        }

        .status-save-btn {
          height: 30px;
          color: $color-white;
          background-color: $color-blue;
          border-color: $color-blue;
          margin-top: 0 !important;
          font-size: 12px;
          font-weight: 500;
          width: 102px;
          padding: 7px;
          line-height: 1.4;
        }
      }
    }
  }

  .ticket-btn {
    min-width: 160px;
    font-size: 14px;
    padding: 7px 15px;
    @media (min-width: 992px) and (max-width: 1500px) {
      min-width: auto;
    }
    @include mediaq(767px) {
      min-width: auto;
      padding: 7px 0px !important;
    }
  }

  .deleteIcon {
    width: 30px;
    height: 30px;
    padding: 6px;
    border-radius: 50px;
    background: #333;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;

    img {
      width: 100%;
    }
  }

  .addTicketForm {
    .add-ticket-btn {
      @include fontSize(12, 14, 500);
    }

    .accordian_arrow {
      img {
        transition: all 0.5s ease-in-out;

        &.down {
          transform: rotate(180deg);
          transition: all 0.5s ease-in-out;
        }
      }

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }

    &:last-child {
      .isOpen {
        margin-bottom: 20px;
      }
    }

    .isOpen {
      margin-bottom: 50px !important;
    }
  }
}

.statusTitle {
  color: $color-black;
}

.tree {
  padding: 0;
  list-style: none;
  color: #369;
  position: relative;

  li {
    margin: 0;
    line-height: 2em; /* default list item's `line-height` */
    font-weight: bold;
    position: relative;
    list-style: none;

    &:last-child {
      &:before {
        background: $color-white; /* same with body background */
        height: auto;
        top: 1em; /* (line-height/2) */
        bottom: 0;
      }
    }
  }

  ul {
    margin: 0 0 0 1em; /* indentation */
    padding: 0;
    list-style: none;
    color: #000;
    position: relative;
    margin-left: 0.5em;

    li {
      margin: 0;
      padding: 0px 5px 0px 19px;
      line-height: 14px; /* default list item's `line-height` */
      font-weight: normal;
      position: relative;
      list-style: none;

      &:before {
        content: "";
        display: block;
        width: 10px; /* same with indentation */
        height: 0;
        border-top: 1px solid;
        margin-top: -5px; /* border top width */
        position: absolute;
        top: 15px; /* (line-height/2) */
        left: 0;
        color: #393939;
      }

      &:last-child {
        &:before {
          background: white; /* same with body background */
          height: auto;
          top: 17px; /* (line-height/2) */
          bottom: 0;
        }
      }
    }

    &:before {
      content: "";
      display: block;
      width: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: 1px solid;
      color: #393939;
    }
    .event-type-tree {
      padding-top: 14px;
      &:before {
        color: #606060;
      }
      li {
        &:before {
          color: #606060;
        }
        span {
          color: #606060;
          span {
            color: #000000;
          }
        }
        .list {
          margin-bottom: 8px !important;
          display: flex;
          align-items: baseline;
        }
      }
    }
  }
}

.without-tree {
  li {
    list-style: none;

    .list {
      margin-bottom: 0;
    }
  }
}

.event-dashboard {
  padding: 39px 0 45px 45px;
  @media (min-width: 992px) and (max-width: 1500px) {
    padding: 39px 0 45px 0px;
  }
  @include mediaq(991px) {
    padding: 0px;
  }
  @include mediaq(767px) {
    padding: 0px;
  }
}

.l-65 {
  left: 65px !important;
}
.ticketFetures-button-view {
  z-index: 999;
  position: sticky;
  bottom: 0;
  background: $color-light-yellow;
  padding: 8px;
  margin-bottom: 60px;
  margin-top: 30px;
  box-shadow: 0px 0px 6px #00000040;
  .app-button {
    border-radius: 3px;
    font-weight: 500;
    padding: 8px 15px;
    width: 33%;
  }
}
.addTicketForm {
  .addTicketFormWrapper {
    h5 {
      word-break: break-all;
      margin-bottom: 15px;
    }
    .col-sm-2 {
      padding-left: 0;
    }
  }
}
@include mediaq(574px) {
  .tickets {
    .addTicketForm {
      .add-ticket-btn {
        margin: 15px 0 !important;
      }
      .isOpen {
        margin-bottom: 24px !important;
      }
      .discountBtn {
        width: 162px;
        height: 30px;
      }
      .addTicketFormWrapper {
        h5 {
          font-size: 16px;
          word-break: break-all;
          line-height: 21px;
        }
        .col-sm-10 {
          flex: 0 0 80%;
          max-width: 80%;
        }
        .col-sm-2 {
          flex: 0 0 20%;
          max-width: 20%;
          padding-left: 0;
          span {
            &:first-child {
              margin-right: 12px !important;
            }
          }
        }
      }
    }
  }
}

.add-card-details-modal {
  max-width: 613px;
  background: $color-white;
  border-radius: 9px;
  margin: 20px auto;
  .modal-content {
    padding: 27px;
    border: none;
    .add-card-detail-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .add-card-detail-title {
        font-size: 30.42px;
        line-height: 38px;
        font-weight: 500;
      }
    }
    .modal-body {
      padding: 0px;
      margin-top: 36px;
      .add-card-detail-sub-title {
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 36px;
      }
      .add-card-detail-footer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 13px;
        padding-top: 27px;
        border-top: 1px solid $gray-300;
      }
    }
  }
}
