@import "src/resources/styles/variables.scss";
@import "src/resources/styles/mixins.scss";

.event-card {
  position: relative;
  height: 100%;
  margin-bottom: 8px !important;
  cursor: pointer;

  .my-contribution-button {
    display: block !important;
    min-height: 45px !important;
    font-size: 20px;
    @media (max-width: 991px) {
      // display: none !important;
      font-size: 18px;
      padding: 5px;
    }
  }

  .my-contribution-button-smallscreen {
    display: none !important;
    @media (max-width: 767px) {
      display: block !important;
      margin: 0px 15px;
    }
  }

  .card-badge {
    position: absolute;
    top: 0;
    right: 30px;
    // @media (max-width: 599px) {
    //   right: 8px;
    // }
  }
  .imgWrapper {
    height: 230px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f1f1f1;
    border-radius: 5px;
  }

  .card-img-top {
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }

  .img-section {
    flex: 0 0 25%;
    max-width: 25.5% !important;
    @media (max-width: 621px) {
      flex: 0 0 0;
      min-width: 100% !important;
    }
  }

  .event-type {
    background-color: $color-yellow;
    padding: 2px 15px 1px 15px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 10px;
    display: inline-block;
  }
  .blur-section {
    opacity: 0.5;
  }
  .card_content {
    font-weight: 500;
  }

  .event-date {
    color: $color-yellow;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0 0 0;
    margin-bottom: 4px;
    @media (max-width: 599px) {
      margin-top: 0;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      margin-bottom: 5px;
    }

    .event-time {
      font-size: 16px;
      font-weight: 700;
      @media (max-width: 599px) {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
      }
      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 1px;
        border-radius: 50%;
        background-color: $color-yellow;
      }
    }
  }

  .event-name {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;

    @media (max-width: 599px) {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      font-weight: 500;
    }

    // @include mediaq(480px) {
    //   margin-bottom: 0px;
    // }
  }

  .eventname-button {
    background: transparent;
    border: none;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-left: 0px;
    font-size: 20px;
    font-weight: 500;
  }

  .time-left,
  .amount-raised {
    //font-size: 16px;

    span {
      font-size: 18px;
      @media (max-width: 599px) {
        color: #000000;
        font-size: 10px;
        line-height: 13px;
        font-weight: 500;
        margin-bottom: 2px;
      }
    }
  }

  .extra-info {
    margin-top: 20px;
    @include mediaq(480px) {
      display: none;
    }

    img {
      margin-right: 10px;
      // cursor: pointer;
    }
  }

  &.isHorizontal {
    margin-bottom: 0;
    border-bottom: 0;
    border-radius: 0;

    .card-img-top {
      height: 162px;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }
}

.item-following {
  .item {
    margin-bottom: 45px;
  }
  .subTitleText {
    color: $color-blue-dark;
    font-size: 14px;
  }
  .carousel {
    height: 100%;
    .carousel-inner {
      height: 100%;
    }
  }
}
.ticket {
  .header {
    display: flex;
    justify-content: space-between;
  }
  .ticket-options {
    display: flex;
  }
  .send-ticket {
    cursor: pointer;
  }
  svg {
    width: 100%;
    height: 100px;
    padding: 0 10px;
  }
}
.carousel {
  .carousel-inner {
    background: none;
    min-height: auto;
    text-align: center;
    width: 100%;
    height: 238px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    img {
      width: 100%;
      height: 100%;
      :hover {
        background-color: transparent;
      }
    }
  }
  .carousel-indicators {
    display: flex;
  }
  .carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 1px solid #ffc629;
    opacity: 1;
  }
  .carousel-indicators .active {
    background-color: #ffc629;
    opacity: 1;
  }
}
.my-contribution {
  .imgWrapper {
    img {
      aspect-ratio: 3 / 2;
      height: 100%;
    }
  }
  .contributionsImage {
    height: 144px;
  }
  .description {
    // text-align: center;
    margin-top: 30px;
    flex: 0 0 20%;
  }
  .right-tab {
    min-height: calc(100vh - 133px);
  }
  .event-title {
    font-size: 22px;
  }
  .date-time {
    color: #ffc629;
    font-weight: 500;
    padding-bottom: 30px;
  }
  .go_to_event_page {
    background-color: #ffc629 !important;
    border-color: #ffc629 !important;
    color: #000000;
    height: 60px !important;
    padding: 8px 20px;
    border: none;
    width: 273px;
    font-size: 20px;
    font-weight: 500;
  }
  .go_to_event_page_button_smallscreen {
    background-color: #ffc629 !important;
    border-color: #ffc629 !important;
    color: #000000;
    height: 60px !important;
    padding: 8px 20px;
    border: none;
    width: 100%;
    font-size: 20px;
    font-weight: 500;
  }
  .item {
    margin-bottom: 45px;
  }
  .title {
    font-size: 60px;
    font-weight: 500;
    line-height: 0.8;
    margin-top: 39px;
    padding-bottom: 55px;
    @media (max-width: 787px) and (min-width: 767px) {
      font-size: 55px !important;
    }
    @media (max-width: 767px) and (min-width: 682px) {
      font-size: 45px !important;
    }
  }
  .sub-title {
    font-size: 40px;
    font-weight: 500;
    padding-bottom: 16px;
  }
}

.responsive_card {
  @include mediaq(767px) {
    padding: 7px 8px;
    display: flex;
    flex-direction: unset;

    .card_image {
      width: 180px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .card-badge {
        width: 24px;
        height: 32px;
      }
    }

    .card_content {
      width: calc(100% - 190px);
    }
  }

  @include mediaq(599px) {
    .card_image {
      width: 92px;
      height: 112px !important;

      .card-badge {
        width: 20.26px;
        height: 27.58px;
      }
    }

    .card_content {
      width: calc(100% - 120px);
    }

    .extra-info {
      display: none;
    }

    .card-body {
      padding: 0 0 0 8px;
    }
  }
}
.noImage {
  height: 100%;
  width: 100%;
  background: #fff8e4;
  border: 4px dashed #ffc629;
}
.printImage {
  aspect-ratio: 3 / 2 !important;
}
.event-list-wrapper {
  .event-card {
    cursor: auto;
  }
}
