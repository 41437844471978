@import "src/resources/styles/variables.scss";
@import "src/resources/styles/mixins.scss";

.AddItemModal {
  .input-group {
    &:hover,
    &:focus,
    &:active {
      .input-group-text {
        border-color: transparent !important;
      }
    }
  }
}
.addItemModalForm {
  .span-style {
    position: absolute;
    z-index: 11;
    top: 12px;
    left: 15px;
    padding-right: 20px;
  }
  .noImageWrap {
    height: 180px;
    width: 100%;
    display: block;
    border: solid 1px #cccccc;
    background: #cccccc;
  }
  .image-item {
    width: 100%;
  }
  .input_field-style {
    padding-left: 60px;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  .messageBoxLot {
    border-bottom: 0.5px solid $color-gray;
    border-left: 0.5px solid $color-gray;
    border-right: 0.5px solid $color-gray;
    padding: 15px 15px 30px;
    border-top: none;
  }
}
.maxwidth-900 {
  max-width: 940px;
  padding: 0;
  @include mediaq(1440px) {
    max-width: 800px;
  }
}
@include mediaq(800px) {
  .addLotModal {
    margin: 1rem !important;
  }
}
@include mediaq(767px) {
  .addLotModal {
    margin: 0.5rem !important;
    .img-specification {
      display: none;
    }
    .modal-body {
      padding: 0px !important;
    }
    Input {
      height: 45px !important;
    }
    .modalHeader {
      padding: 0px 5px 20px !important;
      h2,
      .title {
        font-size: 18px !important;
        padding: 0px;
      }
    }
    .selectBorder {
      height: 45px !important;
      .css-1uccc91-singleValue {
        top: 36% !important;
      }
    }
    .addItemModalForm {
      .switchBtn {
        right: 2px !important;
      }
      h5 {
        font-size: 16px !important;
      }
    }
    .app-button {
      height: 45px !important;
      font-size: 14px !important;
      padding: 0 !important;
    }
    .modalFooter {
      .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0;
        &:nth-child(1) {
          padding-right: 12px;
        }
      }
      .row {
        margin: 0;
      }
      .col-sm-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding: 0;
        &:nth-child(2) {
          padding: 0 12px;
        }
      }
    }
  }
}
@include mediaq(575px) {
  .addLotModal {
    .lot-Images-block {
      .col-sm-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
      .file-uploader {
        width: 100%;
        height: 67px;
        .dropzone {
          width: auto;
          padding: 0 20px;
          min-height: 0;
          border: dashed 2px $color-yellow;
          height: 67px;
          @include mediaq(374px) {
            width: 75px;
            padding: 0;
          }
          p {
            font-size: 15px;
            @include mediaq(433px) {
              font-size: 12px;
            }
            @include mediaq(379px) {
              font-size: 10px !important;
            }
          }
        }
      }
      .image-display-list {
        .image-item {
          width: auto;
          height: 67px;
          margin: 0 auto;
          @include mediaq(374px) {
            width: 75px;
          }
          .longImg {
            width: auto;
            height: 67px;
            margin-right: 0;
            @include mediaq(374px) {
              width: 75px;
            }
          }
          .remove-button {
            width: 15px;
            height: 15px;
            top: 3px;
            right: 3px;
            line-height: 13px;
            @include mediaq(374px) {
              top: 2px;
              right: 2px;
            }
            img {
              width: 6px;
              height: 6px;
            }
          }
        }
      }
    }
  }
}
.after-purchase-modal {
  max-width: 402px;
  position: relative;
  h5 {
    font-size: 21.33px;
    line-height: 25px;
  }
  p {
    font-size: 16px;
    line-height: 18px;
  }
  .buyBtn {
    font-size: 15px;
  }
  @media (max-width: 991px) {
    width: calc(100% - 92px);
    margin: 108px auto;
    h5 {
      font-size: 18px;
      line-height: 25px;
    }

    .buyBtn {
      font-size: 15px;
    }
  }
  .model-close {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 20px;
    z-index: 99999;
  }
  .modal-body {
    padding: 64px 28px 28px 28px;
  }
}
