@import "src/resources/styles/variables.scss";

.event-details-auction-feature {
  margin-top: 45px;
  .feature-auction-title {
    font-size: 23.4px;
    line-height: 29px;
    font-weight: 500;
    margin-bottom: 19px;
  }
  .search-container {
    display: flex;
    align-items: center;
    @media (max-width: 599px) {
      flex-direction: column;
    }
    .search-select {
      max-width: 216px;
      width: 100%;
      @media (max-width: 599px) {
        max-width: 100%;
        margin-bottom: 15px;
      }
      .select__control {
        border-radius: 8px 0px 0px 8px;
        @media (max-width: 599px) {
          border-radius: 8px;
        }
      }
    }
    .auction-search-field {
      @media (max-width: 599px) {
        margin-bottom: 9px;
      }
      .MuiOutlinedInput-root {
        border-radius: 0px 8px 8px 0px;
        @media (max-width: 599px) {
          border-radius: 8px;
        }
      }
      .MuiInputBase-input {
        font-size: 16px;
        line-height: 20px;
        padding: 13px 13px 12px 12px;
      }
      // .MuiOutlinedInput-notchedOutline {
      //   top: -10px;
      // }
    }
    .auction-search-button {
      max-width: 100px;
      width: 100%;
      padding: 11px 22px;
      margin-left: 9px;
      @media (max-width: 599px) {
        max-width: 100%;
      }
    }
  }
  .sort-filter-container {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 27px;
    margin-bottom: 18px;
    .sort-by-text {
      font-size: 13.84px;
      line-height: 18px;
      margin-right: 8px;
    }
    .sort-select {
      max-width: 174px;
      width: 100%;
      .select__control {
        padding: 7px 8px;

        .select__input-container,
        .select__single-value {
          font-size: 13.84px;
          line-height: 18px;
        }
      }
    }
  }
  .auction-lot-card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 26px;

    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
    @media (max-width: 999px) {
      grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }
  .btn-primary {
    font-weight: 500;
  }
}
