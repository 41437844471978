@import "src/resources/styles/variables.scss";

body {
  .event-details-tickets-feature {
    margin-top: 45px;
    .feature-tickets-title {
      font-size: 23.4px;
      line-height: 29px;
      font-weight: 500;
      margin-bottom: 18px;
    }

    .ticket-add-amount-card-container {
      display: grid;
      grid-template-columns: 1fr;
      gap: 26px;
      .blur-section {
        pointer-events: none;
      }
      .ticket-amount-card {
        background: $color-white;
        padding: 27px;
        border-radius: 18px;
        border: 1px solid $gray-300;
        @media (max-width: 1400px) {
          padding: 18px;
        }
        .ticket-amount-title {
          font-size: 18px;
          line-height: 23px;
          font-weight: 500;
          margin-bottom: 18px;
        }
        .ticket-amount-value {
          margin-bottom: 9px;
          span {
            font-size: 30.42px;
            line-height: 38px;
          }
        }
        .ticket-amount-discription {
          font-size: 18px;
          line-height: 23px;
          color: $gray-500;
          margin-bottom: 27px;
        }
        .ticket-amount-future-date-time {
          font-size: 18px;
          line-height: 23px;
          font-weight: 500;
          color: $color-blue-dark;
        }
        .ticket-amount-past-date-time {
          font-size: 18px;
          line-height: 23px;
          font-weight: 500;
          color: $color-green;
        }
        .ticket-amount-exhausted {
          font-size: 18px;
          line-height: 23px;
          font-weight: 500;
          color: $orang-500;
        }
        .ticket-card-amount-update-btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 18px;
          // flex-wrap: wrap;
          @media (max-width: 1300px) {
            flex-direction: column;
            align-items: normal;
          }
          .ticket-update-count-btn {
            min-width: 49px;
            height: 49px;
          }
          .ticket-count-input-field {
            max-width: 67px;
            margin: 0px 9px;
            @media (max-width: 1300px) {
              max-width: 100%;
              width: 100%;
            }
            .ticket-count-field {
              .MuiInputBase-input {
                text-align: center;
              }
              .MuiOutlinedInput-notchedOutline {
                top: -10px;
              }
            }
          }
          .ticket-add-to-cart-btn {
            padding: 13px 26px;
            min-width: 148px;
            width: 100%;
          }
        }
      }
    }
  }
  .event-details-tickets-print-container {
    margin-top: 63px;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 18px;
    flex-wrap: wrap;
    .event-details-tickets-print-sent {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 18px;
      flex-wrap: wrap;
    }
    .event-details-tickets-print-title {
      font-size: 23.4px;
      line-height: 29px;
      font-weight: 500;
    }
    .event-details-tickets-print-link {
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
      text-decoration: underline;
      &.print-link-hide {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
  // tickets modal
  .event-details-ticket-modal {
    max-width: 1247px;

    margin: 20px auto;
    .modal-content {
      padding: 18px;
      border: none;
      background: transparent;
      @media (max-width: 599px) {
        padding: 12px;
      }
      .event-details-ticket-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .event-details-ticket-title {
          font-size: 18px;
          line-height: 23px;
          color: $color-white;
          text-decoration: underline;
        }
      }
      .modal-body {
        padding: 0px;
        .modal-your-tickets-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          background: $color-bg;
          border-radius: 9px;
          margin-top: 17px;
          // height: 100vh;
          @media (max-width: 768px) {
            flex-direction: column;
            // height: 100%;
            margin-top: 9px;
          }
          .modal-your-tickets-left-side {
            max-width: 417px;
            width: 100%;
            padding: 18px 18.6px 18px 18px;
            background: $color-bg;
            border-radius: 18px 0px 0px 18px;
            height: 100%;
            overflow: auto;
            @media (max-width: 768px) {
              max-width: 100%;
              padding: 18px 17px 45px 18px;
              border-radius: 18px 18px 0px 0px;
            }
            .modal-your-tickets-img-wrapper {
              height: 167px;
              background: $gray-300;
              border-radius: 8px;
              @media (max-width: 768px) {
                height: 138.73px;
              }
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 8px;
              }
            }
            .modal-your-tickets-qr-code {
              text-align: center;
              margin-top: 27px;
              margin-bottom: 27px;
            }
            .modal-your-tickets-details {
              text-align: center;
              .modal-your-tickets-title {
                font-size: 13.84px;
                line-height: 18px;
                padding-bottom: 6px;
              }
              .modal-your-tickets-value {
                font-size: 23.4px;
                line-height: 29px;
                font-weight: 500;
              }
            }
          }
          .modal-your-tickets-right-side {
            padding: 54px 27px;
            width: 100%;
            height: 100%;
            overflow: auto;
            background: $color-white;
            border-radius: 0px 8px 8px 0px;
            @media (max-width: 768px) {
              padding: 36px 18px;
              border-radius: 0px 0px 8px 8px;
            }
            .modal-your-ticket-event-name {
              font-size: 39.55px;
              line-height: 50px;
              font-weight: 500;
              margin-bottom: 18px;
              @media (max-width: 768px) {
                font-size: 30.42px;
                line-height: 38px;
              }
            }
            .modal-your-ticket-name {
              font-size: 30.42px;
              line-height: 48px;
              font-weight: 500;
              margin-bottom: 27px;
              @media (max-width: 768px) {
                font-size: 23.4px;
                line-height: 29x;
              }
            }
            .modal-your-ticket-time-continer {
              .modal-your-ticket-time-title {
                font-size: 13.84px;
                line-height: 18px;
                color: $color-gray-light;
                margin-bottom: 6px;
              }
              .modal-your-ticket-date {
                font-size: 30.42px;
                line-height: 38px;
                font-weight: 500;
                margin-bottom: 9px;
                @media (max-width: 768px) {
                  font-size: 23.4px;
                  line-height: 29px;
                }
                span {
                  font-size: 30.42px;
                  line-height: 38px;
                  font-weight: 500;
                  @media (max-width: 768px) {
                    font-size: 23.4px;
                    line-height: 29px;
                  }
                }
              }
              .modal-your-ticket-time {
                font-size: 23.4px;
                line-height: 29px;
                margin-bottom: 27px;
                @media (max-width: 768px) {
                  font-size: 18px;
                  line-height: 23px;
                }
              }
            }
          }
        }
      }
    }
  }

  // send tickets modal
  .sent-tickets-modal {
    max-width: 613px;
    background: $color-white;
    border-radius: 9px;
    margin: 20px auto;
    .modal-content {
      padding: 31px 27px 27px 27px;
      border: none;
      .sent-tickets-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .sent-tickets-title {
          font-size: 30.42px;
          line-height: 38px;
          font-weight: 500;
        }
      }
      .modal-body {
        padding: 0px;
        margin-top: 36px;
        .sent-tickets-wrapper {
          .sent-tickets-form-field-wrapper {
            margin-top: 21px;
            border-top: 1px solid $gray-300;
            .sent-tickets-form-accordion {
              border-top: 1px solid $gray-300;

              &:first-child {
                border: none;
              }
              .sent-tickets-form-accordion-title {
                margin-top: 19px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;
                h5 {
                  font-size: 18px;
                  line-height: 23px;
                  font-weight: 500;
                }
              }
              .sent-tickets-form-accordion-field {
                margin-bottom: 15px;
              }
            }
            .ticket-btn {
              padding-top: 27px;
              border-top: 1px solid $gray-300;
            }
          }
        }
      }
    }
  }

  // ticket print view
  .your-tickets-print-view-container {
    .your-tickets-print-view-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background: $color-bg;
      border-bottom: 1px solid $color-white;
      .your-tickets-print-view-left-side {
        max-width: 280px;
        width: 100%;
        padding: 18px;
        background: $color-bg;
        height: 100%;
        .your-ticket-qr-barcode-continer {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 18px;
          .your-ticket-barcode {
            transform: rotate(270deg);
          }
        }
        .your-tickets-print-view-img-wrapper {
          height: 167px;
          background: $gray-300;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
      .your-tickets-print-view-center {
        padding: 18px;
        width: 100%;
        background: $color-white;
        .your-tickets-print-view-event-name {
          font-size: 39.55px;
          line-height: 50px;
          font-weight: 500;
          margin-bottom: 18px;
        }
        .your-tickets-print-view-name {
          font-size: 30.42px;
          line-height: 48px;
          font-weight: 500;
          margin-bottom: 27px;
        }
        .your-tickets-print-view-time-continer {
          .your-tickets-print-view-from-to-time {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
          }
          .your-tickets-print-view-time-title {
            font-size: 13.84px;
            line-height: 18px;
            color: $color-gray-light;
            margin-bottom: 6px;
          }
          .your-tickets-print-view-date {
            font-size: 30.42px;
            line-height: 38px;
            font-weight: 500;
            margin-bottom: 9px;
            span {
              font-size: 30.42px;
              line-height: 38px;
              font-weight: 500;
            }
          }
          .your-tickets-print-view-time {
            font-size: 23.4px;
            line-height: 29px;
            margin-bottom: 27px;
          }
        }
      }
      .your-tickets-print-view-right-side {
        padding: 18px;
        background: $color-light-black;
        max-width: 280px;
        width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .your-tickets-print-view-details {
          margin-top: 27px;
          .your-tickets-print-view-title {
            font-size: 13.84px;
            line-height: 18px;
            padding-bottom: 9px;
            color: $color-white;
          }
          .your-tickets-print-view-value {
            font-size: 23.4px;
            line-height: 29px;
            font-weight: 500;
            color: $color-white;
          }
        }
        .your-tickets-print-view-prize {
          font-size: 23.4px;
          line-height: 29px;
          color: $color-white;
          margin-top: 18px;
        }
        .your-tickets-print-view-right-event-name {
          font-size: 18px;
          line-height: 23px;
          font-weight: 500;
          margin-top: 54px;
          margin-bottom: 18px;
          color: $color-white;
          border-top: 1px solid $color-white;
          padding-top: 18px;
        }
      }
    }
  }

  // single ticket print view
  .your-tickets-print-single-view-container {
    background: $color-white;
  }
  .your-tickets-print-single-view-wrapper {
    .your-tickets-print-single-view-img-wrapper {
      aspect-ratio: 3 / 2;
      margin-bottom: 18px;
      height: 300px;
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .your-tickets-print-single-view-title {
      font-size: 23.4px;
      line-height: 29px;
      color: $color-gray-light;
    }
    .your-tickets-print-single-view-value {
      font-size: 23.4px;
      line-height: 29px;
      font-weight: 500;
      span {
        font-size: 23.4px;
        line-height: 29px;
        font-weight: 500;
      }
    }
  }
}
