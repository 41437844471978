@import "src/resources/styles/variables.scss";

.image-display-list {
  flex-wrap: wrap;
  .image-item {
    position: relative;
    .remove-button {
      cursor: pointer;
      width: 20px;
      height: 20px;
      text-align: center;
      position: absolute;
      top: 10px;
      right: 10px;
      background: $color-white;
      color: $color-error;
      border-radius: 50% !important;
      box-shadow: 0 2px 5px $color-gray;
      padding: 0;
      border: none;
    }
    .longImg {
      width: 100%;
      height: 180px;
      margin-bottom: 15px;
      object-fit: cover;
      object-position: center;
      margin-right: 12px;
    }
    .pdf {
      width: 180px;
      height: 180px;
      margin-bottom: 15px;
      object-fit: contain;
      object-position: center;
      border: solid 1px $color-gray-very-light;
      background: $color-gray-very-light;
    }
    .pdf-file {
      padding: 60px 10px 0 10px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
    }

    .icon {
      text-align: center;
      margin-top: 10px;

      i {
        font-size: 50px;
        color: $color-gray;
      }
    }
  }
}
