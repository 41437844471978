@import "src/resources/styles/variables.scss";

.raffleTab {
  ul {
    margin-bottom: 1em;
    @media (max-width: 1023px) {
      display: flex;
      justify-content: space-around;
      width: 100% !important;
    }
    li {
      background-color: $color-bg;
      border: 1px solid #dddddd;
      margin-right: 6px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      min-width: 150px;
      text-align: center;
      cursor: pointer;
      @media (max-width: 1023px) {
        min-width: unset;
        width: 32%;
        margin-right: 0px;
      }
      &.active {
        background-color: $color-white;
        color: $color-gray;
        border-bottom: none;
        a {
          border-top: 5px solid $color-yellow !important;
          border-radius: 0;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          color: $color-gray;
          border-bottom: 3px solid $color-white !important;
        }
      }
      a {
        font-size: 16px;
        border: 0;
        border-top: 5px solid transparent !important;
        color: $color-black;
        &:hover {
          border-bottom: none;
        }
      }
    }
  }
  .nav-link {
    padding: 10px 0px;
  }
  .nav-tabs .nav-link {
    margin-bottom: -2px;
  }
  .tab-content {
    .active {
      background-color: $color-white;
      color: $color-black;
    }
  }
  .ticketBundleTab {
    img {
      transition: all 0.5s ease-in-out;
      &.down {
        transform: rotate(180deg);
        transition: all 0.5s ease-in-out;
      }
    }
  }
  .pricesForm {
    .uploader.small {
      width: 100%;
    }
    .sort-lots {
      margin-top: 10px;
    }
  }
  .wonItemPrizeCard {
    .supply-image {
      height: 278px;
    }
    .noImage {
      height: 278px;
      width: 100%;
      background: $color-light-yellow;
      border: 4px dashed $color-yellow;
    }
    .card {
      padding: 10px;
      box-shadow: 1px 1px 2px 1px #ddd;
      border: 0;
      .card-body {
        padding: 0;
        min-height: 134px;
        .card-title {
          font-size: 18px;
          font-weight: 500;
        }
        .card-subtitle {
          &.subTitleText {
            color: $color-blue-sm-dark;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
.add-price-modal {
  .modal-content {
    padding: 20px;
  }
  .modalHeader {
    padding: 15px 20px;
  }
  .addPriceModalForm {
    .uploader.small {
      width: 100%;
    }
  }
  .mWidth-100 {
    min-width: 100%;
  }
  .image-display-list {
    .image-item {
      .longImg {
        aspect-ratio: 3 / 2;
      }
    }
  }
}
.draft_widget {
  max-width: 150px;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  padding: 5px;
  padding-left: 15px;
  box-shadow: 1px 1px 1px 1px #ddd;
  font-size: 12px;
  position: relative;
  font-weight: 500;
  img {
    position: absolute;
    right: -8px;
    top: -8px;
    height: 45px;
    width: 45px;
  }
}
.draw_winner_modal {
  .smallFont {
    font-size: 12px;
  }
  .draw_table {
    th {
      font-weight: 500;
      vertical-align: middle;
      background: #f9f9f9;
      border: 0;
      padding: 0.4rem 0.75rem;
    }
    tbody {
      tr {
        &:first-child {
          td {
            border-top: 0;
          }
        }
      }
    }
    .draw-btn {
      height: 30px;
      text-transform: capitalize;
      min-width: 120px;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.raffleWall {
  background-color: $color-light-yellow;
  .left-side {
    box-shadow: 3px 0 1px #ddd;
    padding-top: 40px;
    min-height: 100vh;
    background-color: $color-white;
    .box {
      margin-left: 30px;
      margin-right: 50px;
      border: dashed 4px $color-yellow;
      background: $color-light-yellow 0% 0% no-repeat padding-box;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-yellow;
      font-size: 18px;
      font-weight: 500;
      min-height: 198px;
      margin-bottom: 40px;
    }
  }
  .raffleWallCard {
    margin-top: 40px;
    .row {
      padding: 15px;
      box-shadow: 1px 1px 2px 1px #ddd;
      border-radius: 4px;
      background-color: $color-white;
    }
    .card-img {
      img {
        width: 100%;
      }
    }
    .card-content {
      margin-left: 15px;
    }
  }
  .ticketSold {
    background-color: $color-light-black;
    color: $color-white;
    font-size: 35px;
    padding: 20px 15px;
    margin: 20px 0 0;
    border-radius: 5px;
  }
  .congratulationOverlay {
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}
.addPriceModalForm {
  .input-group {
    input {
      border-radius: 3px !important;
      height: 45px;
    }
  }
}
.itemDetailsSmallModal {
  .smallBadge {
    padding: 3px 10px;
    border-radius: 20px;
    background-color: $color-blue-dark;
    color: $color-white;
    position: relative;
    top: 0px !important;
    left: 0px !important;
    text-transform: capitalize;
  }
  .app-button {
    @media (max-width: 767px) {
      padding: 8px 15px;
    }
  }
  .underline {
    text-decoration: underline;
  }
  .claimedButton {
    background-color: $color-blue-dark !important;
    border-color: $color-blue-dark !important;
  }
}
.supply-item {
  .raffleTab {
    ul {
      @media (max-width: 1023px) {
        display: flex;
        justify-content: space-around;
        width: 100% !important;
      }
      li {
        margin-right: 6px;
        @media (max-width: 1023px) {
          min-width: unset;
          width: 32%;
          margin-right: 0px;
        }
      }
    }
  }
  .page-title {
    @media (max-width: 991px) {
      margin-top: -29px !important;
      z-index: 0;
      margin-bottom: 36px !important;
      line-height: 33px;
      font-size: 30px;
      text-align: center;
    }
    @media (max-width: 599px) {
      font-size: 25px;
    }
  }
  .add-ticket-btn {
    background-color: $color-yellow;
    border-color: $color-yellow;
    min-width: 100px;
    color: $color-black;
  }
}
.supply-item-details {
  .crossimg {
    width: 12px;
    height: 12px;
  }
  .span-style {
    position: absolute;
    top: 12px;
    left: 15px;
    padding-right: 20px;
  }
  .img-specification {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .from-group-with-span {
    position: relative;
    border-left: 1px !important;
  }
  .input_field-style {
    padding-left: 60px;
    width: 100% !important;
    border-left: 1px;
    border-left-style: solid !important;
    border-color: $color-gray;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }
  .title {
    font-size: 40px;
    font-weight: 500;
    padding-top: 10px;
  }
  .modalHeader {
    display: flex;
    justify-content: space-between;
  }
}
.contactBtn {
  background: none;
  text-decoration: underline;
  border: none;
  color: #007bff;
}
.supply-item-cart {
  .item-status {
    text-transform: capitalize;
  }
  .imgWrapper {
    height: 278px;
    width: 100%;
    background-size: cover !important;
    .draft-icon {
      height: auto;
    }
  }
  .imageOverlay {
    box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.45);
  }
  .image-overlay-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.hasdublicate {
  position: relative;
  padding: 5px;
  position: relative;
  .lot-count {
    position: absolute;
    z-index: 999;
    top: 34px;
    left: 28px;
    span {
      min-width: 49px;
      height: 49px;
      background: $color-yellow;
      border: 3px solid $color-white;
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      padding: 5px;
    }
  }
  .border-box {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background: #cbcbcb;
    position: absolute;
    top: 0;
    border-radius: 5px;
    right: 0;
    border: 1px solid $color-gray;
  }
  .wonItemPrizeCard {
    .card {
      border: 1px solid $color-gray;
      box-shadow: none;
    }
  }
}
// paggination
.raffleTab {
  .tablePagination {
    ul {
      margin-bottom: 0px;
      li {
        background-color: transparent;
        border: none;
        min-width: auto;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
