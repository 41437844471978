.invite {
  height: 45px;
  width: 123px;
  border-radius: 3px;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}

.invite-admin-button {
  height: 47px;
  border-radius: 3px !important;
}
.invite-new-user {
  display: flex;
  @media (max-width: 639px) {
    display: block !important;
  }
}
.search {
  font-weight: 500 !important;
}
.button-section {
  display: flex;
  justify-content: center;
}
.invite-admin-button-smallscreen {
  font-size: 15px !important;
  width: 169px !important;
  @media (max-width: 336px) {
    font-size: 12px !important;
  }
  // margin-right: 15px !important;
}

.react-multi-email {
  span {
    ::placeholder {
      // color: #818181 !important;
      color: red !important;
    }
  }
}

.react-multi-email {
  ::placeholder {
    color: green;
  }
}

input {
  ::placeholder {
    color: pink;
  }
}
