@import 'src/resources/styles/variables.scss';
@import 'src/resources/styles/mixins.scss';
.span-design {
  position: absolute;
  z-index: 11;
  top: 9px;
  right: 0px;
  padding-right: 12px;
  background-color: transparent;
  border: none;
  @include mediaq(575px) {
    top: 7px;
  }
}
.input-filed-style {
  position: relative;
}
// Input {
//   height: 45px !important;
// }
.domain {
  margin-right: 8px;
  font-size: 16px;
}
.main-section {
  margin-top: 30px;
}
.apply-button {
  background: #ffc629;
  padding: 10px 29px;
  border: none;
  margin-left: 14px;
}
.icon-color {
  fill: #314c76 !important;
}
.personalisation-section {
  margin-top: 45px;
}
.personalisation-field {
  margin-top: 15px;
}
.background-title {
  margin-top: 44px;
}
.background-selector {
  margin-bottom: 30px;
}
.profit-fit-title {
  margin-top: 45px;
  margin-bottom: 15px;
}
.color-picker-section {
  position: relative;
}
.color-picker-input {
  margin-bottom: 15px;
  height: 45px;
}
.home-container {
  width: 100%;

  .header {
    width: 100%;
    height: 72px !important;

    .powered-by {
      height: 72px;
      display: flex;
      margin-left: auto;
    }
  }
  .banner {
    .container {
      max-width: 1500px;
      margin: 0 auto;
      // width: 100%;

      .preview-image {
        height: 659px;
        width: 100%;
      }
      .preview {
        width: 100%;
      }
      .title {
        margin-top: 57px;
        font-size: 40px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 45px;
      }
      .signature {
        text-align: center;
        margin-bottom: 11px;
      }
      .description {
        font-size: 20px;
        text-align: center;
      }
      .hr-style {
        margin-bottom: 0px;
      }
      .time-section {
        margin-top: 28.5px;
        text-align: center;
      }
      .time-remaining-label {
        font-size: 18px;
        color: #000000;
        margin-bottom: 5px;
      }
      .time-remaining {
        font-size: 25px;
        color: #314c76;
        font-weight: 500;
        margin-bottom: 12px;
      }
      ul li {
        display: inline;
        margin-right: 44px;
      }
      .color-picker-section {
        position: relative;
        .chrome-picker {
          position: absolute;
          z-index: 100;
          left: 88%;
          top: 25px;
        }
      }
    }
  }
}
.export-cardbody {
  padding: 0px !important;
}
.colorpicker-popover {
  z-index: 12;
  position: absolute;
  left: 88% !important;
  top: 25px !important;
  @include mediaq(991px) {
    left: auto !important;
    right: 10% !important;
  }
}
.background-popover {
  z-index: 9999;
  position: absolute;
  left: 95% !important;
  top: 25px !important;
  @include mediaq(991px) {
    left: auto !important;
    right: 10% !important;
  }
}
.company-logo-header {
  margin-top: 45px;

  .company-header-title {
    margin-bottom: 12px;
  }
}
.btn-status {
  background: #f3f2ed;
  display: flex;
  justify-content: flex-end;
}
.url-link {
  padding: 8px !important;
}
.preview-create-btn {
  border-radius: 3px;
  font-weight: 500;
  padding: 8px 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.create-btn {
  // max-width: 75px;
  height: 30px;
  line-height: 15px;
  font-size: 12px;
  color: #fffbf1;
  background: #0080ff;
  border-color: #0080ff;
}
.save-publish-btn {
  color: #fffbf1;
  background: #0080ff;
  border-color: #0080ff;
  height: 45px;
  &:hover {
    background: #0080ff;
    border-color: #0080ff;
  }
  &:active {
    background: #0080ff;
    border-color: #0080ff;
  }
  &:focus {
    background: #0080ff;
    border-color: #0080ff;
  }
  &:visited {
    background: #0080ff;
    border-color: #0080ff;
  }
}
.white-label-button-view {
  z-index: 999;
  position: sticky;
  bottom: 0;
  background: $color-light-yellow;
  padding: 8px;
  margin-bottom: 20px;
  margin-top: 20px;
  box-shadow: 0px 0px 6px #00000040;
  .app-button {
    border-radius: 3px;
    font-weight: 500;
    padding: 8px 15px;
    width: 33%;
    @include mediaq(575px) {
      width: 50% !important;
      &:nth-child(2) {
        margin-right: 0px !important;
      }
    }
  }
}
.apply-button-mobile {
  display: none;
}
.domainname {
  margin-left: 9px !important;
}
.white-label-wrapper {
  .file-uploader-container {
    display: flex;
    align-items: end;
    .message {
      h3 {
        margin-bottom: 6px !important;
        font-size: 12px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .event-dashboard-heading {
    margin-bottom: 29px !important;
  }
  @include mediaq(575px) {
    .event-dashboard-heading {
      margin-bottom: 25px !important;
    }
    .main-section {
      .url-section {
        font-size: 18px;
      }
      .domain {
        width: 20%;
      }
      .domainname {
        margin-left: 9px !important;
      }
      .apply-button {
        display: none;
      }
      .apply-button-mobile {
        display: block !important;
        width: 100%;
        margin-left: 0px;
        font-size: 14px;
        height: 45px !important;
        margin-top: 15px;
      }
      .question-icon {
        margin-left: 5px !important;
      }
      Input {
        height: 45px !important;
      }
    }
    .company-logo-wrapper {
      margin-top: 0 !important;
    }
    .personalisation-section {
      margin-top: 35px;
      p {
        font-size: 18px;
      }
    }
    .company-header-title {
      font-size: 18px;
    }
    .background-title {
      margin-top: 35px;
      font-size: 18px;
    }
  }
}
.raffle-buy-button {
  max-width: 263px;
  margin: 0 auto;
  font-size: 16px;
  border-radius: 8px;
}
