@import "src/resources/styles/variables.scss";

.event-details-auctionmodal {
  max-width: 940px;

  .img-section {
    .lot-image {
      width: 822px;
      height: 548px;
    }
  }
  .modal-auctionBody {
    padding: 24px;
    .carousel {
      .carousel-inner {
        background: none;
        min-height: auto;
        text-align: center;
        width: 822px;
        height: 548px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        img {
          width: 822px;
          height: 548px;
          :hover {
            background-color: transparent;
          }
        }
      }
      .carousel-indicators {
        display: flex;
      }
      .carousel-indicators li {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid #ffc629;
        opacity: 1;
      }
      .carousel-indicators .active {
        background-color: #ffc629;
        opacity: 1;
      }

      /* .carousel-control-prev-icon {
        background-image: url(../../../../../../resources/images/prevIcon.png);
        opacity: 1;
        width: 20px;
        height: 30px;
      }
      .carousel-control-next-icon {
        background-image: url(../../../../../../resources/images/nextIcon.png);
        opacity: 1;
        width: 20px;
        height: 30px;
      } */
    }
  }
  .text-amount-auction {
    span {
      font-style: normal;
      font-size: 1.25rem;
      line-height: 1.2;
    }
  }
}

.userProfilePicture {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}
// Responsive css start
.event-details-auctionmodal {
  @media (max-width: 959px) {
    margin: 10px;
  }
  .modal-auctionBody {
    @media (max-width: 959px) {
      padding: 0;
      .carousel {
        .carousel-inner {
          width: 100%;
          height: unset;
        }
        .carousel-item {
          height: unset;
        }
        img {
          aspect-ratio: 3 / 2;
          width: 100% !important;
          height: unset !important;
        }
      }
    }
    @media (max-width: 767px) {
      .d-flex {
        display: block !important;
        text-align: center;
        .circle {
          margin: 0 auto;
        }
      }
      .sm-d-flex {
        display: flex !important;
        .bid-details {
          svg {
            width: 35px;
            height: 35px;
          }
          h5 {
            font-size: 18px;
          }
        }
      }
    }
  }

  @media (max-width: 479px) {
    .modal-body {
      padding: 0;
    }
    .sm-fs-12 {
      span {
        font-size: 12px !important;
      }
    }
    .bid-details {
      svg {
        width: 25px;
        height: 25px;
      }
      h5,
      span {
        font-size: 14px !important;
      }
    }
  }
}
// end

// New UI
.bidding-amount-count-details {
  margin-top: 7.3px;
  @media (max-width: 768px) {
    margin-top: 4px;
  }
  .update-count-btn {
    min-width: 45px;
    height: 45px;
  }
  .count-input-text {
    span {
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
    }
  }
  .buy-it-now-btn {
    margin-top: 9px;
    margin-bottom: 9px;

    span {
      font-size: 18px;
      line-height: 23px;
      font-weight: 500;
    }
    &:disabled {
      background-color: $color-light-black;
    }
  }
  .min-max-bid-btn {
    display: flex;
    align-items: center;
    gap: 9px;
    margin-bottom: 9px;
  }
  .min-max-bid-count {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 9px;
    .bid-count-title {
      font-size: 23.4px;
      line-height: 29px;
      font-weight: 500;
      @media (max-width: 599px) {
        font-size: 18px;
        line-height: 23px;
      }
    }
    .bid-count-value {
      text-align: center;
      span {
        font-size: 18px;
        line-height: 23px;
        font-weight: 400;
        color: $gray-500;
      }
    }
  }
}

.event-details-auction-modal {
  max-width: 940px;
  @media (max-width: 959px) {
    margin: 12px;
  }
  .modal-content {
    padding: 54px;
    border: none;
    border-radius: 18px;
    @media (max-width: 959px) {
      padding: 18px;
    }
    .modal-body {
      padding: 0;
      .modal-auctionBody {
        margin-top: 27px;
        @media (max-width: 959px) {
          margin-top: 18px;
        }
        .carousel {
          .carousel-inner {
            background: none;
            min-height: auto;
            text-align: center;
            width: 100%;
            height: 554.67px;
            display: flex;
            align-items: center;
            border-radius: 9px;
            @media (max-width: 999px) {
              height: auto;
            }
            img {
              width: 100%;
              height: 554.67px;
              border-radius: 9px;
              @media (max-width: 959px) {
                aspect-ratio: 3 / 2;
                height: auto;
                width: 100%;
              }
              :hover {
                background-color: transparent;
              }
            }
          }
          .carousel-indicators {
            display: flex;
            margin-bottom: 27.7px;
            & button {
              width: 9px !important;
              height: 9px !important;
              margin: 0px 7px;
              border: 1px solid $color-yellow !important;
              opacity: 1;
            }
          }

          .carousel-indicators li {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border: 1px solid #ffc629;
            opacity: 1;
          }
          .carousel-indicators .active {
            background-color: #ffc629;
            opacity: 1;
          }
        }
        .modal-aucton-lot-details {
          margin-top: 27.3px;

          text-align: center;
          .modal-aucton-lot-title {
            font-size: 39.55px;
            line-height: 50px;
            font-weight: 600;
            margin-top: 27px;
            margin-bottom: 27px;
            @media (max-width: 768px) {
              font-size: 23.4px;
              line-height: 29px;
              margin-top: 18px;
              margin-bottom: 18px;
            }
          }
          .modal-aucton-lot-number {
            font-size: 18px;
            line-height: 23px;
            color: $color-gray-light;
            margin-bottom: 10px;
            @media (max-width: 768px) {
              font-size: 13.84px;
              line-height: 18px;
              margin-bottom: 9px;
            }
          }
          .modal-aucton-lot-provide-by {
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 18px;
            @media (max-width: 768px) {
              font-size: 13.84px;
              line-height: 18px;
            }
          }
        }
        .modal-auction-winner-profile {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 9px;
          .modal-no-winner-profile {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            border: 4px dotted $color-yellow;
            background: $color-light-yellow;
            @media (max-width: 768px) {
              height: 54px;
              width: 54px;
            }
          }
          .modal-auction-lot-winner-title {
            font-size: 18px;
            line-height: 23px;
            margin-bottom: 27px;
            color: $color-blue-dark;
            @media (max-width: 768px) {
              margin-bottom: 18px;
            }
            span {
              font-size: 18px;
              line-height: 23px;
              @media (max-width: 768px) {
                font-size: 13.84px;
                line-height: 18px;
              }
            }
          }
        }
        .modal-bid-details-container {
          background: $gray-200;
          display: flex;
          justify-content: space-around;
          padding: 28px 42px 28px 42px;
          border-radius: 9px;
          margin-bottom: 45px;
          @media (max-width: 768px) {
            padding: 19px 20px 19px 16px;
            margin-bottom: 32px;
          }
          .modal-bid-details {
            text-align: center;
            border-right: 1px solid $gray-300;
            width: 33.3333333333%;
            &:last-child {
              border-right: none;
            }
            .modal-bid-details-icon {
              svg {
                height: 40px;
                width: 40px;
                fill: $color-yellow;
                @media (max-width: 768px) {
                  height: 36px;
                  width: 36px;
                }
              }
            }
            .modal-bid-details-title {
              margin-top: 10px;
              margin-bottom: 8px;
              font-size: 18px;
              line-height: 23px;
              font-weight: 500;
              @media (max-width: 768px) {
                margin-bottom: 4px;
                font-size: 13.84px;
                line-height: 18px;
              }
            }
            .modal-bid-details-value {
              font-size: 23.4px;
              line-height: 29px;
              font-weight: 500;
              color: $color-blue-dark;
              @media (max-width: 768px) {
                font-size: 13.84px;
                line-height: 18px;
              }
              span {
                font-size: 23.4px;
                line-height: 29px;
                font-weight: 500;
                color: $color-blue-dark;
                @media (max-width: 768px) {
                  font-size: 13.84px;
                  line-height: 18px;
                }
              }
            }
          }
        }
        .modal-event-about-title {
          font-size: 23.4px;
          line-height: 29px;
          font-weight: 500;
        }
        .modal-event-about-description {
          font-size: 18px;
          line-height: 27px;
          color: $gray-500;
          margin-bottom: 45px;
        }
        .modal-event-sub-title {
          font-size: 23.4px;
          line-height: 29px;
          font-weight: 500;
          margin-bottom: 18px;
        }
        .modal-event-sub-value {
          font-size: 18px;
          line-height: 23px;
          margin-bottom: 45px;
          color: $gray-500;
        }
      }
    }
  }
}
