@import './../../../../resources/styles/mixins.scss';

.tax-detail {
  width: 400px;
  font-size: 12px;
}

.about_org {
  textarea {
    height: 135px;
    resize: none;
  }
}

.organization_wrap {
  padding: 0 47px;
  @include mediaq(767px) {
    padding: 0;
    .message {
      display: none;
    }
  }
}

.signupOrganization {
  padding-left: 61px;
  padding-right: 0;
  .page-title {
    margin-bottom: 59px;
    margin-top: 39px;
    line-height: 0.8;
    @include mediaq(991px) {
      margin-top: -29px;
      z-index: 0;
      margin-bottom: 36px;
      line-height: 33px;
      font-size: 30px;
      text-align: center;
    }
    @include mediaq(599px) {
      font-size: 25px;
    }
  }
  @include mediaq(599px) {
    padding-left: 12px;
  }
  .signup-page-title {
    margin-bottom: 44px;
    margin-top: 54px;
    line-height: 0.8;
  }
  .title-image-section {
    display: flex;
    flex-direction: column;
  }
  .signup-title-image-section {
    display: flex;
    flex-direction: column-reverse;
  }
  .create-event-container {
    flex: 0 0 88.5%;
    max-width: 87.5%;
    @include mediaq(576px) {
      flex: 100%;
      max-width: 100%;
    }
    .createEvent {
      .file-uploader {
        margin-right: 15px;
        margin-left: 55px;
        @media (max-width: 599px) {
          margin-right: 0;
          margin-left: 0;
        }
        .dropzone {
          width: 180px;
          height: 180px;
          margin-bottom: 30px;
          @media (max-width: 599px) {
            width: 108px;
            height: 108px;
            min-height: 108px;
            margin-bottom: 18px;
          }
          p {
            font-size: 15px;
            line-height: 19px;
            font-weight: 500;
          }
        }
      }
      .file-uploader-container {
        margin-bottom: 30px;
        padding-left: 0px;
        h3 {
          font-size: 16px;
        }
        p {
          font-size: 14px;
        }
      }
      .image-display-list {
        height: 180px;
        width: 180px;
        margin-bottom: 30px;
        @media (max-width: 599px) {
          height: 108px;
          width: 108px;
          margin-bottom: 15px;
        }
        img {
          object-fit: cover !important;
        }
        .image-item {
          .longImg {
            height: 180px;
            object-fit: contain;
            @media (max-width: 599px) {
              height: 108px;
              margin-right: 0;
            }
          }
        }
      }
    }
    .custom-checkbox {
      label {
        padding-left: 10px;
      }
    }
  }
}
.org-button-view {
  z-index: 999;
  position: sticky;
  bottom: 0;
  background: $color-light-yellow;
  // margin-right: -50%;
  // margin-left: -88%;
  padding: 8px;
  margin-bottom: 60px;
  box-shadow: 0px 0px 6px #00000040;

  /*  .button-style {
    // margin-top: 30px;
    // margin-bottom: 60px;
    // width: 42.4%;
    // margin: 0 36.8%;
  } */
  .app-button {
    border-radius: 3px;
    font-weight: 500;
    padding: 8px 15px;
    width: 33%;
  }
}
/* .app-button.disabled { */
// opacity: 1;
// background-color: #7fbcf2;
// border-color: #7fbcf2;
/* } */
