@import "src/resources/styles/variables.scss";

.bankDetails {
  input,
  textarea,
  input:focus,
  textarea:focus {
    color: $color-black;
  }
  &.card-details {
    .dottedBox {
      min-height: 215px;
    }
  }
  .page-title {
    @media (max-width: 991px) {
      margin-top: -29px;
      z-index: 0;
      margin-bottom: 36px !important;
      line-height: 33px;
      font-size: 30px;
      text-align: center;
    }
    @media (max-width: 599px) {
      font-size: 25px;
    }
  }
}

.is-view {
  pointer-events: none;
  opacity: 0.4;
}
