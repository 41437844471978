@import "src/resources/styles/variables.scss";
@import "src/resources/styles/mixins.scss";

.auctionTab {
  ::placeholder {
    color: $color-gray-light !important;
  }
  input,
  textarea,
  input:focus,
  textarea:focus {
    color: $color-black;
  }
  ul {
    margin-bottom: 1em;
    li {
      background-color: $color-bg;
      border: 1px solid #dddddd;
      margin-right: 6px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      min-width: 150px;
      text-align: center;
      cursor: pointer;
      @include mediaq(991px) {
        min-width: 134px;
      }
      &.active {
        background-color: $color-white;
        color: $color-black;
        border-bottom: 0;
        a {
          border-top: 5px solid $color-yellow !important;
          border-radius: 0;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          border-bottom: 3px solid $color-white !important;
        }
      }
      a {
        font-size: 1rem;
        border: 0;
        border-top: 5px solid transparent !important;
        color: $color-black;
        &:hover {
          border-bottom: none;
        }
      }
    }
  }
  .nav-link {
    padding: 10px 0px;
  }
  .nav-tabs .nav-link {
    margin-bottom: -2px;
  }
  .tab-content {
    .active {
      background-color: $color-white;
      color: $color-black;
    }
  }
  .ticketBundleTab {
    img {
      transition: all 0.5s ease-in-out;

      &.down {
        transform: rotate(180deg);
        transition: all 0.5s ease-in-out;
      }
    }
  }
  .pricesForm {
    .uploader.small {
      width: 100%;
    }
  }
  .itemsTab {
    .event-tab {
      border-bottom: 1px solid $color-gray;
      li {
        margin: 0;
        font-style: normal;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        border: 0;
        background-color: transparent;
        color: $color-gray;
        @include mediaq(991px) {
          min-width: 100px;
        }
        &.active {
          color: $color-black;
          border: 0;
          border-bottom: solid 5px $color-yellow;
          padding-bottom: 5px;
        }
      }
      @include mediaq(991px) {
        justify-content: space-between;
        display: flex;
      }
    }
    select {
      @include mediaq(767px) {
        height: 30px !important;
        padding: 0;
      }
    }
    .sort-by {
      position: relative;
      top: -5px;
      margin-right: 10px;
      font-size: 16px;
      @include mediaq(374px) {
        margin-right: 5px;
        font-size: 14px;
      }
    }
    .hambergerImg {
      position: relative;
      top: -10px;
      background: transparent;
      border: 0;
      @include mediaq(767px) {
        padding: 0;
      }
    }
    .pagination {
      li {
        background: transparent;
        border: 0;
        min-width: 25px;
        min-height: 25px;
        &:first-child {
          a {
            min-width: auto;
            min-height: auto;
            background-color: transparent;
            line-height: 15px;
            display: initial;
            span {
              font-size: 30px;
              color: #aaa;
            }
          }
        }
        a {
          background-color: transparent;
          border: none;
          border-radius: 50%;
          line-height: 15px;
          min-width: 25px;
          min-height: 25px;
          padding: 0;
          &:focus {
            box-shadow: none;
          }
        }
        &:last-child {
          a {
            min-width: auto;
            min-height: auto;
            background-color: transparent;
            line-height: 15px;
            display: initial;
            span {
              font-size: 30px;
              color: #aaa;
            }
          }
        }
        &.active {
          a {
            border-top: 0 !important;
            color: $color-black;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $gray-300;
          }
        }
      }
    }
    .auctionItemListTable {
      .list-header {
        font-size: 14px;
        font-weight: 500;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $color-gray;
      }
      .list-content {
        font-size: 14px;
        justify-content: flex-end;
        align-items: center;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid $color-gray;
        .img {
          width: 100%;
          border-radius: 5px;
          height: 50px;
          object-fit: cover;
          position: relative;
        }
        img {
          transition: all 0.5s ease-in-out;

          &.down {
            transform: rotate(180deg);
            transition: all 0.5s ease-in-out;
          }
        }
        .collapse {
          .row {
            margin-bottom: 5px;
          }
        }
      }
      @include mediaq(575px) {
        .col-sm-12 {
          flex: 0 0 100%;
          max-width: 100%;
        }
        .col-sm-2 {
          flex: 0 0 16.666667%;
          max-width: 16.666667%;
          padding: 0px;
        }
        .col-sm-3 {
          flex: 0 0 25%;
          max-width: 25%;
          padding: 0px;
        }
        .col-sm-1 {
          flex: 0 0 8.333333%;
          max-width: 8.333333%;
          padding: 0px;
        }
        .col-sm-5 {
          flex: 0 0 41.666667%;
          max-width: 41.666667%;
          padding: 0px;
        }
        .fs-14 {
          font-size: 12px !important;
        }
      }
    }
  }
}
.find-lot {
  padding-left: 40px !important;
}
.add-price-modal {
  .modal-content {
    padding: 20px;
    @media (max-width: 767px) {
      padding: 10px !important;
    }
  }
  .modal-body {
    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }
  .modalHeader {
    padding: 15px 20px;
  }
  .addItemModalForm {
    .uploader.small {
      width: 100%;
    }
    .message {
      p {
        font-size: 12px;
        line-height: 16px;
        padding-bottom: 1px;
        &:first-child {
          font-weight: 500;
          padding-bottom: 4px;
        }
      }
    }
  }
  .mWidth-100 {
    min-width: 100%;
  }
}
.auction-launch-modal {
  .modal-body {
    .launchModalImg {
      background: #f2f4f6;
      padding: 20px 50px;
      border-radius: 3px;
      box-shadow: 1px 1px 3px 1px $color-gray;
      margin: 30px 80px;
      img {
        width: 100%;
      }
      &:last-child {
        margin: 0 80px;
      }
    }
  }
  .lunch-auction-wrapper {
    @include mediaq(767px) {
      h2 {
        font-size: 20px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
      .modal-body {
        padding: 0;
        .launchModalImg {
          margin: 20px 0;
          padding: 20px;
          img {
            width: 100%;
          }
        }
      }
      .col-sm-2 {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }
      .col-sm-10 {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
      }
    }
  }
  @include mediaq(800px) {
    margin: 0.5rem !important;
  }
}
.draft_widget {
  max-width: 150px;
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  padding: 5px;
  padding-left: 15px;
  box-shadow: 1px 1px 1px 1px #ddd;
  font-size: 12px;
  position: relative;
  font-weight: 500;
  cursor: pointer;
  img {
    position: absolute;
    right: -8px;
    top: -8px;
    height: 45px;
    width: 45px;
  }
}
.markAsReadModal {
  .bodyText {
    font-size: 25px;
    line-height: 33px;
  }
  .draft_widget {
    max-width: 135px;
  }
  @media (max-width: 800px) {
    margin: 1rem 0.5rem !important;
  }
  @media (max-width: 767px) {
    margin: 1rem auto !important;
    width: 353px !important;
    h2 {
      font-size: 18px;
    }
    .bodyText {
      font-size: 16px;
      line-height: 21px;
    }
    .modalHeader {
      padding: 16px 0 !important;
      .col-sm-8 {
        flex: 0 0 60%;
        max-width: 60%;
      }
      .col-sm-4 {
        flex: 0 0 40%;
        max-width: 40%;
        padding-left: 0px !important;
      }
    }
    .modal-content {
      padding: 10px 10px 28px !important;
    }
    .modal-body {
      padding: 0 !important;

      .draw_table {
        .col-sm-12 {
          padding: 0;
        }
        Input {
          height: 45px !important;
        }
        .mt-4 {
          margin-top: 16px !important;
        }
        .mb-5 {
          margin-bottom: 28px !important;
        }
        .fs-16 {
          font-size: 12px;
        }
        .app-button {
          height: 45px;
          padding: 0;
        }
      }
    }
  }
  @media (max-width: 354px) {
    width: 310px !important;
    margin: 0.5rem auto !important;
  }
}
.raffleWall {
  background-color: $color-light-yellow;
  &.auctionWall {
    background-color: $color-white;
  }
  &.auctionSmallWall {
    background-color: $color-white;
    .auctionSmallWallCard {
      margin-top: 40px;
      @include mediaq(1600px) {
        padding: 0px 19px;
      }
      @include mediaq(1279px) {
        padding: 0px 9px;
      }
      @include mediaq(959px) {
        padding: 0px;
      }
      .box {
        padding: 15px;
        box-shadow: 6px 3px 8px 3px #ddd;
        border-radius: 4px;
        background-color: $color-white;
      }
      .card-img {
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .small-card-img img {
        width: 100%;
        height: 142px;
        box-shadow: 0px 6px 6px #00000029;
        border-radius: 5px;
      }
      .card-content {
        margin-left: 15px;
      }
    }

    .auctionWallPlatinumSponsor {
      .sponsor-box {
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $color-gray;
        margin: 10px;
        flex-wrap: wrap;
        margin-bottom: 50px !important;
      }
    }
    .auctionWallPlatinumSponsor {
      h4 {
        font-size: 30px;
        @media (max-width: 1279px) {
          font-size: 22px;
        }
      }
      &:nth-child(1) {
        .sponsor-box {
          font-size: 32px;
          margin: 18px;
          width: 233px;
          height: 233px;
          img {
            width: 100%;
            height: 100%;
          }
          @media (max-width: 1600px) {
            width: 200px;
            height: 200px;
            margin: 12px;
            margin-bottom: 85px !important;
          }
          @media (max-width: 1279px) {
            width: 150px;
            height: 150px;
            margin: 12px;
          }
        }
      }
      &:nth-child(2) {
        .sponsor-box {
          font-size: 28px;
          width: 194px;
          height: 195px;
          margin: 18px;
          @media (max-width: 1600px) {
            width: 160px;
            height: 160px;
            margin: 12px;
            margin-bottom: 85px !important;
          }
          @media (max-width: 1279px) {
            width: 120px;
            height: 120px;
            margin: 12px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &:nth-child(3) {
        .sponsor-box {
          font-size: 27px;
          width: 156px;
          height: 156px;
          margin: 18px;
          @media (max-width: 1600px) {
            width: 130px;
            height: 130px;
            margin: 12px;
            margin-bottom: 85px !important;
          }
          @media (max-width: 1279px) {
            width: 90px;
            height: 90px;
            margin: 12px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &:nth-child(4) {
        .sponsor-box {
          font-size: 28px;
          width: 120px;
          height: 120px;
          margin: 18px;
          @media (max-width: 1600px) {
            width: 100px;
            height: 100px;
            margin: 12px;
            margin-bottom: 85px !important;
          }
          @media (max-width: 1279px) {
            width: 60px;
            height: 60px;
            margin: 12px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .auction-wall-title {
      font-size: 70px;
      font-weight: 500;
      line-height: 80px;
      padding-top: 24px;
    }
  }
  .mainBlock {
    display: flex;
    width: 100%;
  }
  .left-side {
    padding: 45px;
    box-shadow: 3px 0px 7px #00000026 !important;
    background-color: $color-white;
    .box {
      margin-left: 0px !important;
      margin-right: 0px !important;
      border: dashed 4px $color-yellow;
      background: $color-light-yellow 0% 0% no-repeat padding-box;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-yellow;
      font-size: 18px;
      font-weight: 500;
      min-height: 198px;
      margin-bottom: 40px;
    }
    .imageBox {
      width: 100%;
      height: 198px;
    }
    .sponsor-slider-block {
      .carousel-indicators {
        display: none;
      }
      a {
        display: none;
      }
    }
  }
  .rightsideBlock {
    padding: 0 45px;
    width: 70%;
    @include mediaq(1600px) {
      padding: 0;
    }
  }
  .auctionWallCard {
    margin-top: 40px;
    margin-left: 15px;
    margin-right: 15px;
    @include mediaq(1600px) {
      margin: 15px 34px 0 34px;
    }
    @include mediaq(1279px) {
      margin: 15px 24px 0 24px;
    }
    @include mediaq(959px) {
      margin: 15px 15px 0 15px;
    }
    .card-row {
      box-shadow: 4px 3px 4px 3px #ddd;
      border-radius: 4px;
      background-color: $color-white;
    }
    .inner-card-block {
      padding: 30px;
    }
    .card-img {
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .big-card-img img {
      width: 100%;
      height: 272px;
      box-shadow: 0px 6px 6px #00000029;
      border-radius: 5px;
    }
    .card-content-big {
      margin-left: 30px;
    }
    .card-content {
      margin-left: 15px;
    }
  }
  .TicketSoldBlock {
    position: absolute;
    width: 67%;
    bottom: 5px;
    z-index: 1;
    @include mediaq(1600px) {
      padding: 0 0 0 34px;
      width: 70%;
    }
    @include mediaq(1279px) {
      padding: 0 0 0 24px;
    }
    @include mediaq(959px) {
      padding: 0 0 0 15px;
    }
    @include mediaq(767px) {
      width: 100%;
      padding: 0 15px;
      position: sticky;
    }
  }
  .ticketSold {
    background-color: $color-light-black;
    color: $color-white;
    font-size: 35px;
    padding: 20px 15px;
    margin: 20px 0;
    border-radius: 5px;
    @include mediaq(1400px) {
      padding: 15px !important;
      margin: 20px 0;
      border-radius: 5px;
      font-size: 18px !important;
      img {
        width: 24px;
      }
    }
    @include mediaq(767px) {
      width: 100%;
      padding: 8px !important;
      position: sticky;
      font-size: 15px !important;
    }
    .currency-fromate {
      font-size: 35px;
      @include mediaq(1400px) {
        font-size: 18px;
      }
      @include mediaq(767px) {
        font-size: 15px;
      }
    }
  }

  .congratulationOverlay {
    height: 100vh;
    position: fixed;
    top: 0;
    width: 100%;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}
.auctionWallTable {
  margin-top: 50px;
  @include mediaq(1600px) {
    padding: 0 34px;
  }
  @include mediaq(1279px) {
    padding: 0 24px;
  }
  @include mediaq(959px) {
    padding: 0 15px;
  }
  .table-responsive {
    max-height: 80vh;
  }
  .MuiTableHead-root {
    position: sticky;
    display: block;
  }
  .custome-Scrollbar {
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: $color-black;
      border: 1px solid $color-gray;
    }
  }
  table {
    box-shadow: 3px 6px 6px #00000029;
    border-radius: 5px;
    th {
      background: $color-yellow;
      border: none;
      padding: 33px 15px 21px;
      line-height: 33px;
      font-size: 25px;
      word-break: break-word;
    }
    .tableItemNo {
      min-width: 150px !important;
      @include mediaq(757px) {
        min-width: 45px !important;
      }
    }
    tbody {
      border-radius: 4px;
      .sold-lot-overlay {
        box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.45);
        position: relative;
        img {
          left: 0;
          z-index: 1;
          right: 0;
          width: 50%;
          height: 50%;
          overflow: auto;
          margin: auto;
          position: absolute;
        }
      }
      tr {
        td {
          padding: 20px 15px;
          font-size: 25px;
          border: none;
          word-break: break-word;
          .currency-fromate {
            font-size: 25px;
          }
        }

        &:nth-child(odd) {
          background: $color-white;
        }
        &:nth-child(even) {
          background: $color-light-yellow;
        }
      }
    }
  }
}
.add_featurecard {
  .add_feat_title {
    @include defaultFont(20, 24);
    margin-bottom: 14px;
  }
  .add_feat_desc {
    @include defaultFont(16, 21);
    margin-bottom: 25px;
  }
}
// .hasdublicate {
//   position: relative;
//   padding: 5px;
//   .lot-count {
//     position: absolute;
//     z-index: 999;
//     top: 34px;
//     left: 28px;
//     span {
//       width: 49px;
//       height: 49px;
//       background: $color-yellow;
//       border: 3px solid $bg-white;
//       font-size: 18px;
//       line-height: 22px;
//       font-weight: 500;
//     }
//   }

//   .border-box {
//     width: calc(100% - 10px);
//     height: calc(100% - 10px);
//     background: #cbcbcb;
//     position: absolute;
//     top: 0px;
//     border-radius: 5px;
//     right: 0px;
//     border: 1px solid $color-gray;
//   }
//   .wonItemPrizeCard {
//     overflow: hidden;
//     .card {
//       border: 1px solid $color-gray;
//       box-shadow: none;
//     }
//   }
// }
.wonItemPrizeCard {
  .card {
    padding: 10px;
    box-shadow: 1px 1px 2px 1px #ddd;
    border: 0;
    border-radius: 5px;
    .overlayImg {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      max-width: 85px;
      top: 12%;
    }
    .card-body {
      padding: 0;
      .card-title {
        font-size: 18px;
        font-weight: 500;
      }
      .card-subtitle {
        &.subTitleText {
          color: $color-blue-dark;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}
.searchIcon {
  position: relative;
  img {
    position: absolute;
    top: 13px;
    left: 10px;
  }
  input {
    padding-left: 35px;
  }
}
.flex-wrap {
  flex-wrap: wrap;
}
.color-blue {
  color: $color-blue-dark;
}
.auctionFetures-button-view {
  z-index: 999;
  position: sticky;
  bottom: 0;
  background: $color-light-yellow;
  padding: 8px;
  margin-bottom: 30px;
  margin-top: 34px;
  box-shadow: 0px 0px 6px #00000040;
  .app-button {
    border-radius: 3px;
    font-weight: 500;
    padding: 8px 15px;
    width: 33%;
  }
}
@include mediaq(575px) {
  .auctionDetailsForm {
    h5 {
      font-size: 16px;
    }
    .pe-1 {
      padding-right: 15px !important;
    }
  }
  .auctionTab {
    .itemsTab {
      .app-button {
        width: 50%;
        margin-top: 15px !important;
      }
      .auctionLotCard {
        .wonItemPrizeCard {
          .card {
            .card-body {
              .card-title {
                font-size: 16px;
                line-height: 20px;
                &:nth-child(1) {
                  margin-bottom: 0 !important;
                }
                &:nth-child(2) {
                  margin-top: 0 !important;
                  margin-bottom: 14px !important;
                  font-size: 12px;
                  line-height: 16px;
                }
              }
              .card-subtitle,
              span {
                font-size: 12px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1600px) {
  .auctionWallWrapper {
    .mainBlock {
      .left-side {
        .auction-wall-title {
          line-height: 60px;
          font-size: 50px;
        }
        .mb-5 {
          margin-bottom: 15px !important;
        }
        .fs-36 {
          font-size: 24px;
        }
        .fs-63,
        .fs-50 {
          font-size: 40px;
        }
        .imageBox {
          height: 180px;
          object-fit: contain;
        }
        .box {
          min-height: 180px;
          margin-bottom: 18px;
        }
      }
      .rightsideBlock {
        .inner-card-block {
          padding: 15px;
        }
        .card-content-big {
          h1,
          .fs-60 {
            font-size: 40px;
          }
        }
        .big-card-img img {
          height: 200px;
        }
        .small-card-img img {
          height: 100px;
        }
        .auctionSmallWallCard .box {
          padding: 8px;
          .card-content {
            h4 {
              font-size: 18px;
            }
            .mb-4 {
              margin-bottom: 8px !important;
            }
            .fs-25 {
              font-size: 20px;
            }
            .mb-2 {
              margin-bottom: 0px !important;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1400px) {
  .auctionWallWrapper {
    .mainBlock {
      .left-side {
        padding: 15px 25px;
        .auction-wall-title {
          line-height: 50px;
          font-size: 40px;
        }
        .mb-5 {
          margin-bottom: 15px !important;
        }
        .fs-36 {
          font-size: 24px;
        }
        .fs-63,
        .fs-50 {
          font-size: 34px;
        }
        .imageBox {
          height: 140px;
        }
        .box {
          min-height: 140px;
          margin-bottom: 18px;
        }
      }
      .rightsideBlock {
        .big-card-img img {
          height: 160px;
        }
        .inner-card-block {
          padding: 15px;
        }
        .card-content-big {
          h1,
          .fs-60 {
            font-size: 34px;
          }
          .mb-4 {
            margin-bottom: 8px !important;
          }
          h4 {
            font-size: 18px;
          }
        }
        .auctionWallTable {
          margin-top: 15px;

          table {
            th {
              font-size: 18px;
              padding: 8px;
            }
            tbody {
              tr {
                td {
                  font-size: 18px;
                  padding: 8px;
                }
                .currency-fromate {
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@include mediaq(1279px) {
  .auctionWallWrapper {
    .mainBlock {
      .left-side {
        .imageBox {
          height: 115px;
        }
        .box {
          min-height: 115px;
          margin-bottom: 12px;
        }
        .auction-wall-title {
          line-height: 40px;
          font-size: 30px;
          padding-top: 18px;
        }
        .fs-36 {
          font-size: 22px;
        }
        .fs-63,
        .fs-50 {
          font-size: 30px;
        }
      }
      .rightsideBlock {
        padding: 0px;
        .small-card-img img {
          height: 90px;
        }
        .inner-card-block {
          padding: 14px 8px;
        }
        .big-card-img img {
          height: 120px;
        }
        .card-content-big {
          margin-left: 18px;
          h1,
          .fs-60 {
            font-size: 22px;
          }
        }
        .auctionSmallWallCard {
          margin-top: 24px;
          .box {
            padding: 12px 8px;
            .col-sm-8 {
              padding-right: 0 !important;
            }

            .card-content {
              margin-left: 0;
              h4 {
                font-size: 16px;
              }
              .fs-25 {
                font-size: 18px;
              }
              .mb-2 {
                margin-bottom: 0px !important;
              }
            }
          }
        }
        .auctionWallTable {
          table {
            th {
              font-size: 16px;
              padding: 8px 4px;
              line-height: 21px !important;
            }
            tbody {
              tr {
                td {
                  font-size: 16px;
                  padding: 8px 4px;
                }
                .currency-fromate {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@include mediaq(959px) {
  .auctionWallWrapper {
    .mainBlock {
      .left-side {
        padding: 15px 14px;
        .auction-wall-title {
          line-height: 30px;
          font-size: 24px;
          padding-top: 18px;
        }
        .fs-36 {
          font-size: 20px;
        }
        .fs-63,
        .fs-50 {
          font-size: 24px;
        }
      }
      .rightsideBlock {
        .small-card-img img {
          height: 90px;
        }
        .inner-card-block {
          padding: 14px 8px;
        }
        .big-card-img img {
          height: 110px;
        }
        .card-content-big {
          margin-left: 12px;
          h1,
          .fs-60 {
            font-size: 20px;
          }
          h4 {
            font-size: 16px;
          }
        }
        .auctionSmallWallCard {
          margin-top: 24px;
          .box {
            padding: 12px 8px;
            .col-sm-8 {
              padding-left: 8px !important;
            }
            .col-sm-12 {
              padding-left: 0px !important;
            }
            .card-content {
              margin-left: 0;
              h4 {
                font-size: 14px;
                line-height: 24px;
              }
              .fs-25 {
                font-size: 16px;
              }
              .mb-2 {
                margin-bottom: 0px !important;
              }
            }
          }
        }
        .auctionWallTable {
          table {
            th {
              font-size: 16px;
              padding: 8px 4px;
              line-height: 21px !important;
            }
            tbody {
              tr {
                td {
                  font-size: 16px;
                  padding: 8px 4px;
                }
                .currency-fromate {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@include mediaq(767px) {
  .auctionWallWrapper {
    .mainBlock {
      display: block;
      .left-side {
        width: 100%;
        min-height: auto;
        .imageBox {
          width: 60%;
          margin: 0 auto !important;
          display: block;
          height: 140px;
        }
        .box {
          width: 60%;
          min-height: 140px;
          margin: 0 auto !important;
        }
      }
      .rightsideBlock {
        width: 100%;
        .small-card-img img {
          height: 90px;
        }
        .inner-card-block {
          padding: 8px;
        }
        .big-card-img img {
          height: 80px;
        }
        .card-content-big {
          margin-left: 12px;
          h1,
          .fs-60 {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 0;
          }
          h4 {
            font-size: 12px;
            line-height: 18px;
          }
        }
        .auctionSmallWallCard {
          margin-top: 24px;
          .box {
            padding: 12px 8px;

            .card-content {
              margin-left: 0;
              h4 {
                font-size: 12px;
                line-height: 20px;
              }
              .fs-25,
              h6 {
                font-size: 14px;
              }
              .mb-2 {
                margin-bottom: 0px !important;
              }
            }
          }
        }
        .auctionWallTable {
          .col-sm-4 {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
          }
          table {
            th {
              font-size: 14px;
              padding: 4px;
              line-height: normal !important;
            }
            tbody {
              tr {
                td {
                  font-size: 14px;
                  padding: 4px;
                }
                .currency-fromate {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@include mediaq(575px) {
  .auctionWallWrapper {
    .mainBlock {
      .rightsideBlock {
        .small-card-img img {
          height: 70px;
        }
        .auctionSmallWallCard {
          margin-top: 24px;
          .box {
            padding: 4px;
            .card-content {
              margin-left: 0;
              h4 {
                font-size: 14px;
                line-height: 20px;
                margin-top: 8px;
              }
              .currency {
                margin-top: 0px !important;
              }
              .fs-25,
              h6 {
                font-size: 14px;
              }
            }
            .col-sm-8 {
              flex: 0 0 66.666667% !important;
              max-width: 66.666667% !important;
            }
            .col-sm-4 {
              flex: 0 0 33.333333% !important;
              max-width: 33.333333% !important;
            }
          }
        }
      }
    }
  }
}
.wall-card {
  padding: 0px 15px;
}
