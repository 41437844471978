@import "src/resources/styles/variables.scss";

body {
  .custom-tab-container {
    background: $gray-200;
    padding: 6px;
    border-radius: 15px;
    outline: 1px solid $gray-300;
    overflow: auto;
    @media (max-width: 599px) {
      background: transparent;
      padding: 0px;
      border-radius: 0px;
      outline: none;
    }
    .custom-tab-wrapper {
      display: inline-flex;
      gap: 5px;
      .tab-btn {
        color: $color-light-black;
        outline: 2px solid transparent;
        font-family: $regular;
        text-transform: inherit;
        padding: 13px 26px;
        font-size: 18px;
        line-height: 23px;
        font-weight: 500;
        width: max-content;
        &:hover {
          color: $color-light-black;
          background-color: $color-white;
          opacity: 0.8;
        }
        &:focus {
          outline: 2px solid transparent;
        }
      }
      .active-tab-btn {
        outline: 2px solid $btn-border-gray;
        background-color: $color-white;
        color: $color-blue-dark;
        border-radius: 8px;
        &:hover {
          color: $color-blue-dark;
          background-color: $color-white;
          opacity: 0.8;
        }
        &:focus {
          outline: 2px solid $btn-border-gray !important;
        }
      }
    }
    // mobile tab view
    .tab-dropdown {
      width: 100%;
      background: $gray-200;
      .MuiOutlinedInput-notchedOutline {
        top: -9px;
        border: 2px solid $btn-border-gray;
        border-radius: 8px;
      }
      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border-color: $color-yellow !important;
        }
      }
      .MuiSelect-select {
        padding: 13px;
        text-align: center;
        font-size: 18px;
        line-height: 23px;
        color: $color-blue-dark;
        font-weight: 500;
      }
      .MuiSelect-icon {
        right: 15px;
        top: 20px;
      }
    }
  }

  .custom-dropdown-tab-wrapper {
    background: $color-white;
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    min-width: auto !important;
    .MuiMenu-list {
      background: $color-white;
      padding: 0px 18px 9px 18px;
      .MuiMenuItem-root {
        font-size: 18px;
        line-height: 23px;
        justify-content: center;
        min-height: auto;
        padding: 9px;
        border-bottom: 1px solid $btn-border-gray;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
